





























import { defineComponent, computed, onBeforeMount, nextTick } from '@vue/composition-api'

import Stepper from '@/elements/Stepper.vue'
import InfoStepUR from '@/components/UpwardRunning/ProductFlow/InfoStepUR.vue'
import StarterOrderItemStepUR from '@/components/UpwardRunning/ProductFlow/StarterOrderItemStepUR.vue'
import ParticipantKitStepUR from '@/components/UpwardRunning/ProductFlow/ParticipantKitStepUR.vue'
import RunningTotalUR from '@/components/UpwardRunning/ProductFlow/RunningTotalUR.vue'
import Loading from '@/elements/Loading.vue'
import CurrencyWarning from '@/components/ProductFlow/ProductSetupStepper/CurrencyWarning.vue'
import { setupStepperLogicUR } from '@/components/UpwardRunning/ProductFlow/setupStepperLogicUR'
import store from '@/store'

export default defineComponent({
  name: 'SetupStepperRunning',
  components: {
    Stepper,
    InfoStepUR,
    StarterOrderItemStepUR,
    ParticipantKitStepUR,
    RunningTotalUR,
    Loading,
    CurrencyWarning,
  },
  setup() {
    const stepperLogic = setupStepperLogicUR()

    onBeforeMount(async () => {
      stepperLogic.currentStep.value = 0
      await stepperLogic.initialize()
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up

      nextTick(() => (stepperLogic.currentStep.value = 1)) //trigger expansion of first step
    })

    const loading = computed(() => {
      if (store.getters.urProductFlow.isNewProdConfig) {
        return stepperLogic.stepComponents.value.length == 0
      } else {
        return (
          !store.getters.productOfferings.initialProductFlowLoadingComplete &&
          stepperLogic.stepComponents.value.length == 0
        )
      }
    })

    return {
      loading,
      ...stepperLogic,
    }
  },
})
