export enum UpwardOpportunityTypes {
  CLINIC = 3,
  CAMP = 2,
  LEAGUE = 1,
  LICENSING = 5,
  CONNECT = 9,
  UPWARDSELECT = 10,
  UPWARDRUNNING = 11,
}

export enum UpwardOpportunityTypesByUpwardTypeID {
  CLINIC = 'CLINIC',
  CAMP = 'CAMP',
  LEAGUE = 'LEAGUE',
  LICENSING = 'LICENSING',
  CONNECT = 'CONNECT',
  UPWARDSELECT = 'UPWARDSELECT',
  UPWARDRUNNING = 'UPWARDRUNNING',
}
