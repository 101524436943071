
























import { defineComponent, ref, onMounted } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import RoleResources from '@/views/Programs/Resources/RoleResources.vue'
import ResourceList from '@/views/UpwardRunning/Resources/ResourceList.vue'
import AdCommissionerResources from '@/views/Programs/Resources/AdCommissionerResources.vue'
import contentManagementClient from '@/clients/contentManagementClient'
import { Resource } from '@/models/Program/Resource'
import Loading from '@/elements/Loading.vue'
import PageContent from '@/components/PageContent.vue'
import ToggleSwitchInput from '@/elements/ToggleSwitchInput.vue'
import ResourceTile from '@/views/UpwardRunning/Resources/ResourceTile.vue'
import store from '@/store'

export default defineComponent({
  name: 'Resources',
  components: {
    VerticalTabs,
    Loading,
    RoleResources,
    AdCommissionerResources,
    PageContent,
    ToggleSwitchInput,
    ResourceList,
    ResourceTile,
  },
  setup() {
    const resources = ref<Resource[]>([])
    const marketingResources = ref<Resource[]>([])
    const allResources = ref<Resource[]>([])
    const loading = ref(false)

    function dateFilter(r: Resource) {
      const now = new Date()
      return (
        r.expirationDate === null ||
        r.expirationDate === '0001-01-01T00:00:00' ||
        new Date(r.expirationDate) > now
      )
    }

    onMounted(async () => {
      window.scrollTo(0, 0)
      await fetchResources()
    })

    async function fetchResources() {
      loading.value = true
      try {
        const challenge = store.getters.upwardRunning.currentChallenge
        resources.value = []

        const myMarketingResources = contentManagementClient.getVolunteerResourcesRunning(
          challenge.upwardChallengeID ?? ''
        )
        const [marketing]: Array<any> = await Promise.allSettled([myMarketingResources])
        marketingResources.value = marketing.value.data.filter(dateFilter).map((r: Resource) => {
          r.leagueDirectoryTaxonomy = ['Planning / Advertising']
          return r
        })
        allResources.value = marketingResources.value
        loading.value = false
      } catch (err) {
        console.error(err)
        loading.value = false
      }
    }

    return {
      loading,
      marketingResources,
      allResources,
    }
  },
})
