















































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import Vue from 'vue'
import EnlargeableProduct from '@/components/ProductFlow/ParticipantKitStepper/ProductPickers/EnlargeableProduct.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { RunningOrderProductInfoEXT } from '@/models/UpwardRunning/RunningOrderProductInfoEXT'
import { ProductOfferingProductColorInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductColorInfo'
import {
  PartnerChallengeProductConfig,
  getEmptyPartnerChallengeProductConfig,
} from '@/models/UpwardRunning/PartnerChallengeProductConfig'
import { sortOffering } from '@/services/offeringService'
import { cloneDeep } from 'lodash'
import store from '@/store'

import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'
import { getColorDropDownItems } from '@/lib/common/upwardColorTypes'

interface SelectedProductColor {
  upwardProductID: string
  selectedColor: string | null
}

export default defineComponent({
  name: 'ProductPickerUR',
  components: {
    EnlargeableProduct,
    SelectorCard,
    SelectInput,
  },
  props: {
    products: {
      type: Array as PropType<RunningOrderProductInfoEXT[]>,
      default: [],
      required: false,
    },
    selectMultiple: { type: Boolean, default: true, required: false },
    required: { type: Boolean, default: true, required: false },
    selectable: { type: Boolean, required: true },
  },
  setup(props) {
    const productConfig = computed(() => store.getters.upwardRunning.currentChallenge.productConfig)
    const valueLocal = ref<number | number[] | null>(props.selectMultiple ? [] : null)
    const selectedColors = ref<SelectedProductColor[]>([])
    const showColorMessage = ref<boolean[]>([])

    const logic = productFlowLogicUR()

    onMounted(() => {
      valueLocal.value = getLocal()
      selectedColors.value = getSelectedColorsArray(productConfig.value, sortedProducts.value)
    })

    function getSelectedIndexes(
      details: PartnerChallengeProductConfig[] | null,
      allProducts: RunningOrderProductInfoEXT[]
    ) {
      if (!details) return

      const indexes = allProducts.reduce((a: any, p: RunningOrderProductInfoEXT, i: number) => {
        const hasId = details.some(
          (x: PartnerChallengeProductConfig) => x.upwardProductID === p.upwardProductID
        )

        if (hasId) a.push(i)
        return a
      }, [])

      return indexes
    }

    function getLocal() {
      const indexes = getSelectedIndexes(productConfig.value, sortedProducts.value)
      if (props.selectMultiple) {
        return indexes ?? []
      } else {
        if (Array.isArray(indexes)) {
          return indexes[0]
        } else {
          return indexes
        }
      }
    }

    function manageButtonAction(p: RunningOrderProductInfoEXT, index: number, toggle: () => void) {
      if (p.colors && p.colors.length === 0) {
        Vue.set(showColorMessage.value, index, false)
        toggle()
      } else {
        Vue.set(showColorMessage.value, index, true)
      }
    }

    function getProductSelectedColor(id: string) {
      let retval: string | null = null
      const psc = selectedColors.value.find((x) => x.upwardProductID == id)
      if (psc != null) {
        retval = psc.selectedColor
      }
      return retval
    }

    function controlColorCard(color: string, productId: string, productIndex: number) {
      if (!color) {
        deselectButtonOnColorCard(productIndex)
      } else {
        selectButtonOnColorCard(color, productId, productIndex)
      }
      Vue.set(showColorMessage.value, productIndex, false)
    }

    function selectButtonOnColorCard(color: string, productId: string, productIndex: number) {
      const psc = selectedColors.value.find((x) => x.upwardProductID == productId)
      if (psc) {
        psc.selectedColor = color
      }
      if (Array.isArray(valueLocal.value)) {
        valueLocal.value.push(productIndex)
        valueLocal.value = [...new Set(valueLocal.value)]
      } else {
        valueLocal.value = productIndex
      }
    }

    function deselectButtonOnColorCard(productIndex: number) {
      if (Array.isArray(valueLocal.value)) {
        const idx = valueLocal.value.findIndex((v: number) => v === productIndex)
        valueLocal.value.splice(idx, 1)
      } else {
        valueLocal.value = null
      }
    }

    const sortedProducts = computed(() => sortOffering<RunningOrderProductInfoEXT>(localClone.value))

    const localClone = computed(() => cloneDeep(props.products))

    function getColorDropDown(colors: ProductOfferingProductColorInfo[]) {
      return getColorDropDownItems(colors)
    }

    function getSelectedColorsArray(
      details: PartnerChallengeProductConfig[] | null,
      products: RunningOrderProductInfoEXT[]
    ) {
      const colors: SelectedProductColor[] = []

      if (details == null) details = []

      products.forEach((p: RunningOrderProductInfoEXT) => {
        if (!p.colors || p.colors.length < 1) return // this prod has no colors

        const detail = details?.find(
          (i: PartnerChallengeProductConfig) => i.upwardProductID === p.upwardProductID
        )

        colors.push({
          upwardProductID: p.upwardProductID!,
          selectedColor: detail ? detail.upwardColorTypeID : null,
        })
      })
      return colors
    }

    function convertToSaveFormat(product: RunningOrderProductInfoEXT) {
      if (!product) return
      const config = getEmptyPartnerChallengeProductConfig()
      config.challengeID = logic.challenge.value.id
      config.upwardProductID = product.upwardProductID
      if (product.colors && product.colors.length > 0) {
        const psc = selectedColors.value.find((x) => x.upwardProductID == product.upwardProductID)
        const color = psc?.selectedColor
        config.upwardColorTypeID = color ? color : ''
      }
      return config
    }

    function commitSavedProductDetails() {
      if (Array.isArray(valueLocal.value)) {
        // selectMultiple is true, so an array is returned from v-item-group
        arrayCommit()
      } else {
        // selectMultiple is false, so a single number is returned from v-item-group
        soloCommit()
      }

      logic.markAsDirty()
    }

    function arrayCommit() {
      const local = valueLocal.value as number[]

      const details = local.map((i: number) => {
        if (local !== null && sortedProducts.value) {
          return convertToSaveFormat(sortedProducts.value[i])
        }
      })
      if (details.length) {
        const productDetails = JSON.parse(JSON.stringify(details))
        setChangedProductDetails(productDetails)
      } else {
        setChangedProductDetails([])
      }
    }

    function soloCommit() {
      const local = valueLocal.value as number
      let sel!: PartnerChallengeProductConfig | undefined

      if (sortedProducts.value) {
        sel = convertToSaveFormat(sortedProducts.value[local])
      }

      if (sel) {
        setChangedProductDetails([sel])
      }
    }

    function setChangedProductDetails(details: PartnerChallengeProductConfig[]) {
      logic.setProductConfig(details)
    }

    watch(
      () => selectedColors.value,
      () => {
        commitSavedProductDetails()
      },
      { deep: true }
    )

    watch(
      () => valueLocal.value,
      () => {
        commitSavedProductDetails()
      },
      { deep: true }
    )

    watch(
      () => props.products,
      () => {
        selectedColors.value = getSelectedColorsArray(productConfig.value, sortedProducts.value)
      },
      { deep: true }
    )

    return {
      valueLocal,
      sortedProducts,
      manageButtonAction,
      showColorMessage,
      selectedColors,
      getProductSelectedColor,
      controlColorCard,
      getColorDropDown,
    }
  },
})
