import { VueConstructor } from 'vue/types/umd'
import SelectKit from '@/views/UpwardRunning/Orders/orders/steps/SelectKit.vue'
import SelectPromoCards from '@/views/Programs/Orders/orders/steps/SelectPromoCards.vue'
import Shipping from '@/views/UpwardRunning/Orders/orders/steps/Shipping.vue'
import Review from '@/views/UpwardRunning/Orders/orders/steps/Review.vue'
import Confirmation from '@/views/UpwardRunning/Orders/orders/steps/Confirmation.vue'
import {
  ProductSelectorLogicGenerator,
  useNoProductSelector,
  useProductSelector,
} from '@/views/UpwardRunning/Orders/compositions/useProductSelector'
import SelectQuantities from '@/views/UpwardRunning/Orders/orders/steps/SelectQuantities.vue'
import SelectManualOrderType from '@/views/Programs/Orders/orders/steps/SelectManualOrderType.vue'

export interface StepProperties {
  //** label to attach
  label: string
  //** step to use
  title: string
  //** component to render to complete step -- note these components (can) take league and account and emit events on-enable and on-disable
  component: VueConstructor
  //** provides the next button, don't draw it.
  providesNext?: boolean
}

interface OrderConfiguration {
  steps: StepProperties[]
  productSelectorLogicGenerator: ProductSelectorLogicGenerator
}

export const ConfigurationWithPromoCard: OrderConfiguration = {
  steps: [
    {
      label: 'SELECT',
      title: 'Select Products',
      component: SelectKit,
    },

    {
      label: 'CUSTOMIZE',
      title: 'Custom Print Items',
      component: SelectPromoCards,
    },
    {
      label: 'QUANTIFY',
      title: 'Select Product Quantities',
      component: SelectQuantities,
    },
    {
      label: 'SHIPPING',
      title: 'Confirm Your Shipping Address',
      component: Shipping,
    },
    {
      label: 'REVIEW',
      title: 'Review Your Order',
      component: Review,
      providesNext: true,
    },
    {
      label: 'CONFIRM',
      title: 'Confirmation',
      component: Confirmation,
      providesNext: true,
    },
  ],
  productSelectorLogicGenerator: useProductSelector,
}
export const ConfigurationWithoutPromoCard: OrderConfiguration = {
  steps: [
    {
      label: 'SELECT',
      title: 'Select Product Quantities',
      component: SelectQuantities,
    },
    {
      label: 'SHIPPING',
      title: 'Confirm Your Shipping Address',
      component: Shipping,
    },
    {
      label: 'REVIEW',
      title: 'Review Your Order',
      component: Review,
      providesNext: true,
    },
    {
      label: 'CONFIRM',
      title: 'Confirmation',
      component: Confirmation,
      providesNext: true,
    },
  ],
  productSelectorLogicGenerator: useNoProductSelector,
}

export const ConfigurationForManualOrder: OrderConfiguration = {
  steps: [
    {
      label: 'ORDERTYPE',
      title: 'Select Order Type',
      component: SelectManualOrderType,
    },
    {
      label: 'SELECT',
      title: 'Select Product Quantities',
      component: SelectQuantities,
    },
    {
      label: 'SHIPPING',
      title: 'Confirm Your Shipping Address',
      component: Shipping,
    },
    {
      label: 'REVIEW',
      title: 'Review Your Order',
      component: Review,
      providesNext: true,
    },
    {
      label: 'CONFIRM',
      title: 'Confirmation',
      component: Confirmation,
      providesNext: true,
    },
  ],
  productSelectorLogicGenerator: useNoProductSelector,
}
