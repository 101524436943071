
































import SectionHeader from '@/components/SectionHeader.vue'
import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import ShippingMethodSection from '@/components/ShippingMethodSection.vue'
import OrderListSection from '@/components/Orders/OrderListSection.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { xShipMethodBase } from '@/GeneratedTypes/xOrder/xShipMethodBase'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { ProductSelectorLogic } from '@/views/UpwardRunning/Orders/compositions/useProductSelector'
import store from '@/store'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {
    SectionHeader,
    ShippingAddressSection,
    ShippingMethodSection,
    OrderListSection,
    InputLabel,
  },

  props: {
    selectorMethod: {
      type: Function as PropType<ProductSelectorLogic>,

      required: true,
    },
    orderType: { type: String as PropType<OrderTypesEnum>, required: true },
  },
  setup(props, ctx) {
    const currentOrderShipTo = computed(() => store.getters.upwardRunningOrders.currentOrderShipTo)
    const currentOrderShippingMethods = computed(
      () => store.getters.upwardRunningOrders.currentOrderShippingMethods
    )
    const currentOrderProducts = computed(() => store.getters.upwardRunningOrders.currentOrderProducts)
    const currentOrderNotes = computed(() => store.getters.upwardRunningOrders.currentOrderNotes)
    const setCurrentOrderShippingMethods = store.commit.upwardRunningOrders.setCurrentOrderShippingMethods

    function update(value: xShipMethodBase[]) {
      setCurrentOrderShippingMethods({ orderType: props.orderType as OrderTypesEnum, item: value })
    }
    function goNext() {
      ctx.emit('go-next')
    }

    return {
      goNext,
      update,
      currentOrderProducts,
      currentOrderShippingMethods,
      currentOrderNotes,
      currentOrderShipTo,
    }
  },
})
