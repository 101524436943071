/*
 * Logic that is shared across the overall product flow
 */
import { computed } from '@vue/composition-api'
import { PartnerChallengeStartupProductConfig } from '@/models/UpwardRunning/PartnerChallengeStartupProductConfig'
import { PartnerChallengeProductConfig } from '@/models/UpwardRunning/PartnerChallengeProductConfig'
import store from '@/store'

export function productFlowLogicUR() {
  /* productFlow Store */
  const isNewProdConfig = computed(() => store.getters.urProductFlow.isNewProdConfig)
  const productSetupMaxTouchedStep = computed(() => store.getters.urProductFlow.maxTouchedProductSetupStep)
  const setCurrentProductSetupStep = store.commit.urProductFlow.setCurrentProductSetupStep
  const setIsNewProdConfig = store.commit.urProductFlow.setIsNewProdConfig
  const challenge = computed(() => store.getters.upwardRunning.currentChallenge)

  /* authorization Store */
  const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)

  /*
   * ProductSetupStepper Logic
   */
  function productSetupNextStep(currentStep: number) {
    const steps = store.getters.urProductFlow.steps
    const stepName = steps[currentStep]
    store.commit.urProductFlow.addViewedStep({ step: stepName })

    const next = currentStep + 1
    setCurrentProductSetupStep({ step: next })
    setMaxTouchedProductSetupStep(next)
  }

  function setMaxTouchedProductSetupStep(nextStep: number) {
    const max = store.getters.urProductFlow.maxTouchedProductSetupStep
    if (nextStep >= max) {
      store.commit.urProductFlow.setMaxTouchedProductSetupStep({ step: nextStep })
    }
  }

  function markAsDirty() {
    if (store.getters.productOfferings.initialProductFlowLoadingComplete) {
      store.commit.promptToSave.setIsDirty({ isDirty: true })
    }
  }

  function productSetupStepClicked(step: number) {
    setCurrentProductSetupStep({ step })
  }

  /*
   * ParticipantKitStepper 1 Logic
   */
  const kitMaxTouchedStep = computed(() => store.getters.urProductFlow.maxTouchedParticipantKitStep)

  function kitNextStep(currentStep: number) {
    const next = currentStep + 1
    store.commit.urProductFlow.setCurrentParticipantKitStep({ step: next })
    setMaxTouchedParticipantKitStep(next)
  }

  function setMaxTouchedParticipantKitStep(nextStep: number) {
    const max = store.getters.urProductFlow.maxTouchedParticipantKitStep
    if (nextStep >= max) {
      store.commit.urProductFlow.setMaxTouchedParticipantKitStep({ step: nextStep })
    }
  }

  function kitClicked(step: number) {
    store.commit.urProductFlow.setCurrentParticipantKitStep({ step })
  }

  /*
   * Step actions
   */
  function nextStep(step: number) {
    kitNextStep(step)
  }

  function kitStepClicked(step: number) {
    kitClicked(step)
  }

  function setStartupOrderProducts(products: PartnerChallengeStartupProductConfig[]) {
    store.commit.upwardRunning.setChallengeStartupConfig({ products: products })
  }

  function setProductConfig(products: PartnerChallengeProductConfig[]) {
    store.commit.upwardRunning.setChallengeProductConfig({ products: products })
  }

  /*
   * Fetch product catalog
   */
  async function fetchProductCatalog() {
    store.commit.urProductFlow.setProductOfferingLoading({ loading: true })
    store.commit.productOfferings.setInitialProductFlowLoadingComplete({ status: false })

    try {
      await store.dispatch.productOfferings.fetchTopLevelProducts({
        accountNumber: firstAccountNumber.value,
        leagueType: challenge.value.typeLeagueID ?? '',
        firstPracticeDate: challenge.value.startDate,
      })
      await store.dispatch.upwardRunning.loadRunningOrderProducts({
        typeLeagueID: challenge.value.typeLeagueID ?? '',
        startDate: challenge.value.startDate!,
      })
    } finally {
      store.commit.urProductFlow.setProductOfferingLoading({ loading: false })
      store.commit.productOfferings.setInitialProductFlowLoadingComplete({ status: true })
    }
  }

  /*
   * Utilites
   */

  function adjustIndex(i: number) {
    // Add 1 to convert 0 base index to 1 base
    // Add another 1 to account for product-group-picker (offering) in the stepper
    return i + 2
  }

  return {
    challenge,
    firstAccountNumber,
    productSetupNextStep,
    productSetupMaxTouchedStep,
    productSetupStepClicked,
    nextStep,
    kitStepClicked,
    kitMaxTouchedStep,
    adjustIndex,
    fetchProductCatalog,
    markAsDirty,
    isNewProdConfig,
    setStartupOrderProducts,
    setProductConfig,
    setIsNewProdConfig,
  }
}
