



















import { defineComponent, computed, ref, onUnmounted } from '@vue/composition-api'

import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import Loading from '@/elements/Loading.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'

import store from '@/store'

export default defineComponent({
  name: 'UpwardRunningIndex',
  components: {
    FullBody,
    HorizontalTabs,
    Loading,
    AlertCloak,
  },
  setup(props, ctx) {
    const currentChallenge = computed(() => store.getters.upwardRunning.currentChallenge)

    const alertTypes = ref([AlertCloakTypes.PARTNER, AlertCloakTypes.LEAGUE])
    const uncloak = ref(false)

    const loading = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const tabs = computed(() => {
      return [
        { text: 'Setup', to: `/programs/running/${routeId.value}setup` },
        { text: 'Participants', to: `/programs/running/${routeId.value}participants`, disabled: !id.value },
        // {
        //   text: 'Communications',
        //   to: `/programs/running/${routeId.value}communications`,
        //   disabled: !id.value,
        // },
        { text: 'Ordering', to: `/programs/running/${routeId.value}ordering`, disabled: !id.value },
        // { text: 'Reports', to: `/programs/running/${routeId.value}reports`, disabled: !id.value },
        { text: 'Resources', to: `/programs/running/${routeId.value}resources`, disabled: !id.value },
      ]
    })

    onUnmounted(() => {
      //store.commit.upwardRunning.clearCurrent()
    })

    //async created hack from https://stackoverflow.com/questions/64117116/how-can-i-use-async-await-in-the-vue-3-0-setup-function-using-typescript
    const run = (asyncFn: () => Promise<void>) => asyncFn()

    //private async created() {
    // Reset ministryAgreementSigned so that every time a league
    // is loaded, it's seasonEndDate is checked.
    run(async () => {
      store.commit.authorization.resetMinistryAgreementIsSigned()
      if (ctx.root.$route.params.id) {
        loading.value = true
        await store.dispatch.upwardRunning.retrieveAndSetAsCurrent({ id: ctx.root.$route.params.id })
        loading.value = false
      } else {
        // new league, skip alerts
        uncloak.value = true
      }
    })
    //end created

    return {
      loading,
      alertTypes,
      id,
      uncloak,
      currentChallenge,
      tabs,
    }
  },
})
