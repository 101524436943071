

















import { defineComponent, ref, computed } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'
import ProgramInformationFormUR from './ProgramInformationFormUR.vue'

import { StepperHooks } from '@/components/ProductFlow/models/StepperHooks'
import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'

import store from '@/store'

export default defineComponent({
  name: 'InfoStepUR',
  props: { step: { type: Number, required: true } },
  components: { ProgramInformationFormUR, Step },
  setup() {
    const logic = productFlowLogicUR()

    /*
     * Hooks
     */
    const hooks: StepperHooks = {
      beforeNextStep: () => {
        return validate()
      },
      afterNextStep: async () => {
        if (store.getters.urProductFlow.isNewProdConfig) {
          await logic.fetchProductCatalog()
        }
      },
    }

    /*
     * Form validation and conditional display
     */
    const showInforWarning = computed(() => {
      return programInfoClicked.value && !programInformationIsValid.value
    })

    const programInformationIsValid = ref(false)
    function onProgramInformationValidation(isValid: boolean) {
      programInformationIsValid.value = isValid
    }

    const programInfoClicked = ref(false)
    function validate() {
      if (programInformationIsValid.value) {
        programInfoClicked.value = false
      } else {
        programInfoClicked.value = true
      }
      return programInformationIsValid.value
    }

    return {
      onProgramInformationValidation,
      showInforWarning,
      hooks,
      ...logic,
    }
  },
})
