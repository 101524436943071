























import { defineComponent, computed } from '@vue/composition-api'

import StartupOrderPickerUR from '@/components/UpwardRunning/ProductFlow/StartupOrderPickerUR.vue'
import Step from '@/components/ProductFlow/Step.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'

export default defineComponent({
  name: 'StarterOrderItemStepUR',
  props: { step: { type: Number, required: true } },
  components: { Step, StartupOrderPickerUR, Loading },
  setup() {
    const logic = productFlowLogicUR()

    const loadingOfferings = computed(() => store.getters.urProductFlow.productOfferingLoading)
    const starupProducts = computed(() => store.getters.productOfferings.availableStartupProducts)

    return { starupProducts, loadingOfferings, ...logic }
  },
})
