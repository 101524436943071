


























import SelectorCard from '@/components/SelectorCard.vue'
import EnlargeableProduct from '@/components/ProductFlow/ParticipantKitStepper/ProductPickers/EnlargeableProduct.vue'
import ProductSelect from '@/components/Orders/ProductSelect.vue'

import { computed, defineComponent, watch } from '@vue/composition-api'
import { useProductSelector } from '@/views/UpwardRunning/Orders/compositions/useProductSelector'
export default defineComponent({
  name: 'SelectKit',
  components: {
    SelectorCard,
    ProductSelect,
    EnlargeableProduct,
  },

  setup(props, ctx) {
    //only two product selectors right now, one that is active and one that is a null-op
    //obviously if we are in a product selector having one that defaults all products
    //selected is a bad thing.
    const ps = useProductSelector()()

    const enabled = computed(() => ps.productsSelected.value.length > 0)
    watch(
      () => enabled.value,
      (b) => {
        if (b) {
          ctx.emit('on-enable')
        } else {
          ctx.emit('on-disable')
        }
      },
      { immediate: true }
    )

    return { ...ps }
  },
})
