




























import { computed, defineComponent } from '@vue/composition-api'
import Order from '@/views/UpwardRunning/Orders/vues/Order.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

export default defineComponent({
  name: 'OrderFrame',
  components: {
    Order,
    AlertCloak,
  },

  setup(props, ctx) {
    return {
      cloakable: computed(() => true),
      alertTypes: [AlertCloakTypes.SALES_TAX],
      orderStep: computed(() => ctx.root.$route.params.step),
      orderType: computed(() => OrderTypesEnum.runningStartup),
      orderStepChanged: async (step: string) => {
        const lowerStep = step.toLocaleLowerCase()
        if (ctx.root.$route.params.step != lowerStep) {
          await ctx.root.$router.push({
            params: { ...ctx.root.$router.currentRoute.params, step: lowerStep },
          })
        }
      },
    }
  },
})
