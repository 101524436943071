



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { namespace as upwardRunningStoreName } from '@/store/upwardRunning'
import { namespace } from 'vuex-class'

const upwardRunning = namespace(upwardRunningStoreName)

/**types */
import { DataTableSelection, DataTablePagination } from '@/models/DataTable/DataTableSelection'

/**components */
import FullBody from '@/components/FullBody.vue'
import SearchInput from '@/elements/SearchInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'

import { useFeatureFlags } from '@/services/useFeatureFlags'
import Loading from '@/elements/Loading.vue'
import credentialsService from '@/services/credentialsService'
import jwtService from '@/services/jwtService'
import store from '@/store'

import { DataTableHeader } from 'vuetify/types'
import { PartnerChallengeParticipantInfo } from '@/models/UpwardRunning/PartnerChallengeParticipantInfo'
import dayjs from 'dayjs'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'

@Component({
  setup() {
    return { ...useFeatureFlags() }
  },
  components: {
    Loading,
    FullBody,
    HorizontalTabs,
    SearchInput,
    DataTable,
  },
})
export default class ParticipantsView extends Vue {
  searchTerm = ''
  showDelete = false

  loadRunningParticipants = store.dispatch.upwardRunning.loadRunningParticipants
  setCurrentParticipantByID = store.dispatch.upwardRunning.setCurrentParticipantByID
  setPagination = store.commit.upwardRunning.setPagination
  clearCurrentParticipant = store.commit.upwardRunning.clearCurrentParticipant

  /** getters */
  @upwardRunning.Getter('participants') participantsUR!: PartnerChallengeParticipantInfo[]
  @upwardRunning.Getter('currentParticipant') currentParticipant!: PartnerChallengeParticipantInfo
  @upwardRunning.Getter('currentChallenge') currentChallenge!: PartnerChallenge
  @upwardRunning.Getter('pagination') pagination!: DataTablePagination
  @upwardRunning.Getter('loading') loading!: boolean

  checkContacts = false
  checkAddress = false

  private headers: DataTableHeader[] = [
    {
      text: 'Name',
      value: 'prettyName',
      sort: (a: string, b: string) => {
        return a.toLowerCase().localeCompare(b.toLowerCase())
      },
    },
    { text: 'Gender', value: 'gender' },
    { text: 'Birthday', value: 'prettyDate' },
    { text: 'Phone', value: 'phoneNumber' },
  ]

  async refreshParticipants() {
    await this.loadRunningParticipants({ upwardRunningId: this.$route.params.id })
  }

  /***
   * Route changing logic, looking at handling delete, and index, Former mostly,
   * for delete we need a participant id.
   */
  @Watch('$route', { immediate: true, deep: true })
  async routeChange(to: Route) {
    this.clearCurrentParticipant()

    try {
      if (to?.params?.pid) {
        await this.setCurrentParticipantByID({
          id: parseInt(to.params.pid),
        })
      }
    } catch (e) {
      await this.$router.push('../list')
      throw new Error(e.message)
    }
  }

  onPagination(data: DataTablePagination) {
    this.setPagination({ pagination: data })
  }

  get account() {
    return credentialsService.getPrimaryAccountFromCredentials(jwtService.getCredentialsFromStoredAuthToken())
  }

  participantProgramCount(id: number) {
    return this.participantsUR.filter((x) => x.individualID == id).length
  }

  /***
   * Visible participants are influenced by the current tab
   */
  get visibleParticipants() {
    const participants = this.participantsUR
      .map((p) => {
        return {
          ...p,
          prettyName: `${p.lastName}, ${p.firstName}`,
          prettyDate: dayjs(p.birthDate ?? '').format('MM/DD/YYYY'),
        }
      })
      .sort((a, b) => (`${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}` ? 1 : -1))

    return participants
  }

  get filteredParticipants() {
    return this.visibleParticipants.filter(
      (x) =>
        x.firstName &&
        x.lastName &&
        `${x.firstName} ${x.lastName!}`.toLocaleLowerCase().search(this.searchTerm.toLocaleLowerCase()) != -1
    )
  }

  get upwId() {
    return this.$route.params.id
  }

  mounted() {
    this.clearCurrentParticipant = store.commit.upwardRunning.clearCurrentParticipant
    this.loadRunningParticipants = store.dispatch.upwardRunning.loadRunningParticipants
    this.setCurrentParticipantByID = store.dispatch.upwardRunning.setCurrentParticipantByID
    this.refreshParticipants()
  }

  destroyed() {
    this.clearCurrentParticipant()
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */
  /** index into this.leagues.programs[] variables  */

  async setLocalCurrentParticipantID(id: number) {
    await this.setCurrentParticipantByID({ id: id })
    if (
      this.currentParticipant.street1 ||
      this.currentParticipant.street2 ||
      this.currentParticipant.subdivision1 ||
      this.currentParticipant.subdivision2 ||
      this.currentParticipant.postalCode
    ) {
      this.checkAddress = true
    } else {
      this.checkAddress = false
    }
    if (this.currentParticipant.emailAddress || this.currentParticipant.phoneNumber) {
      this.checkContacts = true
    } else {
      this.checkContacts = false
    }
  }

  rowSelected(x: DataTableSelection<PartnerChallengeParticipantInfo>) {
    const id = x.item.individualID
    if (id) {
      this.setLocalCurrentParticipantID(id)
    }
  }

  getUDFValue(udfID: number) {
    const currentUDF = this.currentParticipant.udFs?.find((x) => x.udfid == udfID)
    return currentUDF?.udfValue
  }
}
