










import { defineComponent, computed, onMounted } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'

import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

export default defineComponent({
  name: 'OrdersIndex',
  components: {
    VerticalTabs,
    Loading,
  },
  setup(props, ctx) {
    const id = computed(() => ctx.root.$route.params.id)
    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    /**
     * uses route to determine that we are in a camp or clinic.
     * @private
     */

    const baseRoute = computed(() => {
      return `/programs/running/${routeId.value}ordering/`
    })

    const tabs = computed(() => {
      return [
        { text: 'Shipping Timelines', to: `${baseRoute.value}shippingtimelines` },
        { text: 'Order History', to: `${baseRoute.value}history` },
        { text: 'Startup', to: `${baseRoute.value}products/${OrderTypesEnum.runningStartup}/select` },
        // { text: 'Running', to: `${baseRoute.value}running` },
        // { text: 'Returns', to: `${baseRoute.value}returns` },
      ].filter((x) => x.text)
    })

    onMounted(() => {
      // If there is no program, then this route is invalid
      if (!routeId.value) {
        ctx.root.$router.push('/programs/new/')
      }
    })

    return {
      tabs,
    }
  },
})
