
























































// TODO: Once warnings are included in the API, make sure they show (already in markup)

import { defineComponent, computed, PropType } from '@vue/composition-api'
import { PartnerChallengeInfo } from '@/models/UpwardRunning/PartnerChallengeInfo'
import ProgramIcon from '@/components/ProgramIcon.vue'
import date from '@/filters/date'
import ProgramOverview from '@/components/ProgramOverview.vue'

export default defineComponent({
  name: 'UpwardRunningOverview',
  components: {
    ProgramIcon,
    ProgramOverview,
  },
  filters: {
    date,
  },
  props: {
    program: { type: Object as PropType<PartnerChallengeInfo>, required: true },
    showNameOnly: { type: Boolean, required: false, default: false },
    link: { type: String, required: false, default: '' },
  },
  setup(props) {
    const registrationUrl = computed(
      () =>
        `${process.env.VUE_APP_RUNNING_REGISTRATION_DOMAIN}registration?id=${props.program.upwardChallengeID}`
    )

    const baseLink = computed(() => {
      const base = `/programs/running/${props.program.upwardChallengeID}`
      if (props.link) {
        return `${base}${props.link}`
      }
      return base
    })

    const defaultLink = computed(() => {
      if (!props.showNameOnly) {
        return `${baseLink.value}/setup/information`
      }
      return baseLink.value
    })

    return {
      defaultLink,
      baseLink,
      registrationUrl,
    }
  },
})
