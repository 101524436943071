/*
 * Logic that is shared across SetupSteppers for Running
 */

import { computed, onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'

import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'

export interface StepComponent {
  name: string
  index: number
  stepTitle: string
  nextOfferingIndex: number
}

export function setupStepperLogicUR() {
  const viewedSteps = computed(() => store.getters.urProductFlow.viewedSteps)
  const logic = productFlowLogicUR()
  const stepComponents = ref<StepComponent[]>([])

  const stepListNames = {
    programInfo: 'Program Information',
    startupOrderItems: 'Startup Order Items',
    participants: 'Participants',
  }

  /*
   * Manage state of steps
   */

  function openAllSteps() {
    const steps = store.getters.urProductFlow.steps
    steps.forEach((step) => {
      store.commit.urProductFlow.addViewedStep({ step })
    })
  }

  const currentStep = computed({
    get() {
      return store.getters.urProductFlow.currentProductSetupStep
    },
    set(v: number) {
      store.commit.urProductFlow.setCurrentProductSetupStep({ step: v })
    },
  })

  function maxoutProductStepperTouchedSteps() {
    store.commit.urProductFlow.setMaxTouchedProductSetupStep({ step: viewedSteps.value.length + 1 })
  }

  /*
   * Warnings
   */

  const showCurrencyWarning = computed(() => {
    if (store.getters.partners.currencyWarningNeeded && logic.isNewProdConfig.value) {
      return !store.getters.partners.hasAcceptedCurrencyWarning
    }
    return false
  })

  /*
   * Initialization
   */

  async function initialize() {
    primeStepperValues()
    await primeProductOfferingStore()
  }

  function primeStepperValues() {
    logic.setIsNewProdConfig({ isNew: isNewProgram.value })
  }

  async function primeProductOfferingStore() {
    if (logic.isNewProdConfig.value) {
      setStepList()
    } else {
      await logic.fetchProductCatalog()
      setStepList()
      await openAllSteps()
      maxoutProductStepperTouchedSteps()
    }
  }

  function setStepList() {
    if (!logic.challenge) {
      return
    }

    const stepList: string[] = []
    stepComponents.value = []

    let i = 1
    stepComponents.value.push({
      name: 'InfoStepUR',
      index: i++,
    } as StepComponent)
    stepList.push(stepListNames.programInfo)

    stepComponents.value.push({
      name: 'StarterOrderItemStepUR',
      index: i++,
    } as StepComponent)
    stepList.push(stepListNames.startupOrderItems)

    stepComponents.value.push({
      name: 'ParticipantKitStepUR',
      index: i++,
      stepTitle: 'Build Running Kit',
    } as StepComponent)
    stepList.push(stepListNames.participants)

    store.commit.urProductFlow.setStepList({ stepList: stepList })
  }

  const isNewProgram = computed(() => {
    const id = logic.challenge.value.upwardChallengeID
    const detailsCount = logic.challenge.value.productConfig.length ?? 0
    if (id || detailsCount > 0) {
      return false
    }
    return true
  })

  /*
   * Unmount
   */

  onUnmounted(() => {
    store.commit.productOfferings.clear()
    store.commit.urProductFlow.clear()
  })

  return {
    openAllSteps,
    currentStep,
    stepComponents,
    showCurrencyWarning,
    viewedSteps,
    initialize,
    stepListNames,
  }
}
