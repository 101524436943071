import { defineMutations, defineGetters } from 'direct-vuex'

export interface ProductFlowState {
  viewedSteps: string[]
  isNewProdConfig: boolean | null
  steps: string[]
  productOfferingLoading: boolean

  //ProductSetup
  currentProductSetupStep: number
  maxTouchedProductSetupStep: number

  //Kit
  currentParticipantKitStep: number
  maxTouchedParticipantKitStep: number
}

const initProductFlowState = (): ProductFlowState => {
  return {
    viewedSteps: [],
    isNewProdConfig: null,
    steps: [],
    productOfferingLoading: true,
    currentProductSetupStep: 1,
    maxTouchedProductSetupStep: 1,
    currentParticipantKitStep: 1,
    maxTouchedParticipantKitStep: 1,
  }
}

const urProductFlowState = initProductFlowState()

const getterTree = defineGetters<ProductFlowState>()({
  currentProductSetupStep: (state) => {
    return state.currentProductSetupStep
  },
  maxTouchedProductSetupStep: (state) => {
    return state.maxTouchedProductSetupStep
  },

  currentParticipantKitStep: (state) => {
    return state.currentParticipantKitStep
  },
  maxTouchedParticipantKitStep: (state) => {
    return state.maxTouchedParticipantKitStep
  },

  viewedSteps: (state) => {
    return state.viewedSteps
  },
  isNewProdConfig: (state) => {
    return state.isNewProdConfig
  },
  steps: (state) => {
    return state.steps
  },
  productOfferingLoading: (state) => {
    return state.productOfferingLoading
  },
})

const mutations = defineMutations<ProductFlowState>()({
  setCurrentProductSetupStep(state, { step }: { step: number }) {
    state.currentProductSetupStep = step
  },
  setMaxTouchedProductSetupStep(state, { step }: { step: number }) {
    state.maxTouchedProductSetupStep = step
  },

  setCurrentParticipantKitStep(state, { step }: { step: number }) {
    state.currentParticipantKitStep = step
  },
  setMaxTouchedParticipantKitStep(state, { step }: { step: number }) {
    state.maxTouchedParticipantKitStep = step
  },
  setIsNewProdConfig(state, { isNew }: { isNew: boolean }) {
    state.isNewProdConfig = isNew
  },
  setStepList(state, { stepList }: { stepList: string[] }) {
    state.steps = stepList
  },
  addViewedStep(state, { step }: { step: string }) {
    state.viewedSteps.push(step)
    state.viewedSteps = [...new Set(state.viewedSteps)]
  },
  setProductOfferingLoading(state, { loading }: { loading: boolean }) {
    state.productOfferingLoading = loading
  },
  clear(state) {
    Object.assign(state, initProductFlowState())
  },
})

export const namespace = 'urProductFlow'

export const urProductFlow = {
  namespaced: true as true,
  state: urProductFlowState,
  getters: getterTree,
  mutations,
}
