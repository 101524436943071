import { UpwardRunningCredentials } from '@/models/UpwardRunning/Auth/UpwardRunningCredentials'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import store from '.'
import restServiceUR from '@/services/restServiceUR'
import upwardRunningClient from '@/clients/upwardRunningClient'
import jsonwebtoken from 'jsonwebtoken'
import { UpwardRunningJwtPayload } from '@/models/UpwardRunning/Auth/UpwardRunningJwtPayload'
import dayjs from 'dayjs'

interface UpwardRunningAuthState {
  urCreds: UpwardRunningCredentials | null
}

const upwardRunningAuthState: UpwardRunningAuthState = {
  urCreds: null,
}

export enum getterNames {
  urCreds = 'urCreds',
}

const getterTree = defineGetters<UpwardRunningAuthState>()({
  urCreds: (state) =>
    state.urCreds == null || state.urCreds.displayName == '@@_NOT_SUBSCRIBED_@@' ? null : state.urCreds,
})

export enum mutationNames {
  setURCreds = 'setURCreds',
}

const mutations = defineMutations<UpwardRunningAuthState>()({
  setURCreds(state, { creds }: { creds: UpwardRunningCredentials | null }) {
    state.urCreds = creds
  },
})

export enum actionNames {
  getURCreds = 'getURCreds',
}

const actions = defineActions({
  async getURCreds({ state }): Promise<UpwardRunningCredentials | null> {
    if (state.urCreds == null || dayjs().isAfter(state.urCreds.expiration)) {
      await getURCredsFromAPI(store.getters.authorization.userData)
    }
    if (state.urCreds == null || state.urCreds.displayName == '@@_NOT_SUBSCRIBED_@@') {
      return null
    } else {
      return state.urCreds
    }
  },
})

async function getURCredsFromAPI(userData: string) {
  store.commit.upwardRunningAuth.setURCreds({ creds: null })
  restServiceUR.setToken(null)
  const urToken = await upwardRunningClient.loginWithLegacyToken(userData)
  if (urToken != null && urToken.hasUpwardRunningSubscription) {
    const decodedJwt = jsonwebtoken.decode(urToken.upwrJWToken, { complete: true })

    if (decodedJwt != null && typeof decodedJwt !== 'string') {
      const jwtPayload = decodedJwt.payload as UpwardRunningJwtPayload
      console.log('TNT decoded token', jwtPayload)
      store.commit.upwardRunningAuth.setURCreds({
        creds: {
          displayName: jwtPayload['upwr-name'],
          emailAddress: jwtPayload['upwr-email'],
          expiration: new Date(jwtPayload.exp * 1000),
        },
      })
      restServiceUR.setToken(urToken.upwrJWToken)
    }
  } else {
    store.commit.upwardRunningAuth.setURCreds({
      creds: {
        displayName: '@@_NOT_SUBSCRIBED_@@',
        emailAddress: '@@_NO_EMAIL@@',
        expiration: new Date(30 * 60 * 1000),
      },
    })
  }
}

export const namespace = 'upwardRunningAuth'

export const upwardRunningAuth = {
  namespaced: true as true,
  state: upwardRunningAuthState,
  getters: getterTree,
  actions,
  mutations,
}
