













import { defineComponent, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'SetupCompleteConfirmationUR',
  components: {
    Modal,
  },
  props: {
    value: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(props.value)

    function hideModal() {
      modalIsVisible.value = false
      onModalChange()
    }

    watch(
      () => props.value,
      () => {
        modalIsVisible.value = props.value
      }
    )

    function onModalChange() {
      ctx.emit('input', modalIsVisible.value)
      ctx.emit('change', modalIsVisible.value)
    }

    return {
      modalIsVisible,
      onModalChange,
      hideModal,
    }
  },
})
