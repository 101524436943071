






































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import { PartnerChallengeUDFDefinition } from '@/models/UpwardRunning/PartnerChallengeUDFDefinition'
import YesNoInput from '@/elements/YesNoInput.vue'

interface GenericUdf {
  udfid: number
  label: string
  sortOrder: number
  isPrivate: boolean
  isRequired: boolean
}

export default defineComponent({
  name: 'AdditionalParticipantInformationUR',
  components: {
    Modal,
    TextInput,
    YesNoInput,
  },
  props: {
    udfs: { type: Array as PropType<PartnerChallengeUDFDefinition[] | null>, required: true },
    challengeID: { type: Number, required: true },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(false)
    const modalTitle = ref('Additional Participant Information')
    const wasCancelled = ref(false)

    const internalUdfs = ref<PartnerChallengeUDFDefinition[]>([])

    watch(
      () => modalIsVisible.value,
      () => {
        if (!modalIsVisible.value && !wasCancelled.value) {
          setInternalUdfArrays()
        }
        wasCancelled.value = false
      }
    )

    function done() {
      ctx.emit('onSave', getRetunArray(props.udfs, internalUdfs.value, createUdf))
      toggleModal()
    }

    function cancel() {
      wasCancelled.value = true
      setInternalUdfArrays()
      toggleModal()
    }

    function toggleModal() {
      modalIsVisible.value = !modalIsVisible.value
    }

    watch(
      () => props.udfs,
      () => {
        internalUdfs.value = ensureArrayHasElementsCount(props.udfs, createUdf, 3)
      },
      { immediate: true }
    )

    function getRetunArray<T extends GenericUdf>(
      originalArray: T[] | null,
      internalArray: T[],
      createElement: (
        udfId: number,
        label: string,
        sortOrder: number,
        isPrivate: boolean,
        isRequired: boolean
      ) => T
    ): T[] {
      const resultArray: T[] = []

      for (const updateUdf of internalArray) {
        const originalUdfResult =
          originalArray && updateUdf.udfid > 0 //#4936 if this is a new UDF then don't find match in old array
            ? originalArray.filter((item) => item.udfid === updateUdf.udfid)
            : null
        let originalUdf: T

        if (!originalUdfResult || originalUdfResult.length === 0) {
          originalUdf = createElement(
            updateUdf.udfid,
            updateUdf.label,
            updateUdf.sortOrder,
            updateUdf.isPrivate,
            updateUdf.isRequired
          )
        } else {
          originalUdf = originalUdfResult[0]
          originalUdf.isPrivate = updateUdf.isPrivate
          originalUdf.label = updateUdf.label
          originalUdf.sortOrder = updateUdf.sortOrder
          originalUdf.isRequired = updateUdf.isRequired
        }

        if (originalUdf.label) {
          resultArray.push(originalUdf)
        }
      }

      return resultArray
    }

    function ensureArrayHasElementsCount<T extends GenericUdf>(
      srcArray: T[] | null,
      createElement: (
        udfId: number,
        label: string,
        sortOrder: number,
        isPrivate: boolean,
        isRequired: boolean
      ) => T,
      elementCount: number
    ): T[] {
      const destArray: T[] = []

      if (srcArray && srcArray.length > 0) {
        for (const item of srcArray) {
          destArray.push(
            createElement(item.udfid, item.label, item.sortOrder, item.isPrivate, item.isRequired)
          )
        }
      }

      if (destArray.length < elementCount) {
        const numberOfItemsToAdd = elementCount - destArray.length

        for (let i = 0; i < numberOfItemsToAdd; i++) {
          destArray.push(createElement((destArray.length + 1) * -1, '', destArray.length + 1, false, false))
        }
      }
      return destArray
    }

    function createUdf(
      udfId: number,
      label: string,
      sortOrder: number,
      isPrivate: boolean,
      isRequired: boolean
    ): PartnerChallengeUDFDefinition {
      return {
        challengeID: props.challengeID,
        udfid: udfId,
        label,
        sortOrder,
        isPrivate,
        isRequired,
      } as PartnerChallengeUDFDefinition
    }

    function setInternalUdfArrays() {
      internalUdfs.value = ensureArrayHasElementsCount(props.udfs, createUdf, 3)
    }

    return {
      modalIsVisible,
      modalTitle,
      internalUdfs,
      cancel,
      done,
      toggleModal,
    }
  },
})
