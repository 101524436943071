export interface PartnerChallengeStartupProductConfig {
  challengeID: number | null
  upwardProductID: string | null
  upwardColorTypeID: string | null
  upwardSizeTypeID: string | null
  quantity: number | null
}

export function getEmptyPartnerChallengeStartupProductConfig() {
  return {
    challengeID: null,
    upwardProductID: null,
    upwardColorTypeID: 'N/A',
    upwardSizeTypeID: 'N/A',
    quantity: 0,
  } as PartnerChallengeStartupProductConfig
}
