






import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'ProgramIcon',
  props: {
    description: { type: String, required: true },
  },
  setup(props) {
    const iconCssClass = computed(() => {
      const defaultIcon = 'fas fa-bible'

      if (!props.description) {
        return defaultIcon
      }

      const description = props.description.toLowerCase()
      if (description.includes('soccer') || description.includes('futsal')) {
        return 'fa fa-futbol'
      }

      if (description.includes('soccer')) {
        return 'fa fa-futbol'
      }

      if (description.includes('basketball')) {
        return 'fa fa-basketball-ball'
      }

      if (description.includes('baseball')) {
        return 'fa fa-baseball-ball'
      }

      if (description.includes('softball')) {
        return 'fa fa-baseball-ball'
      }

      if (description.includes('football')) {
        return 'fa fa-football-ball'
      }

      if (description.includes('volleyball')) {
        return 'fas fa-volleyball-ball'
      }

      if (description.includes('cheer')) {
        return 'fa fa-bullhorn'
      }

      if (description == 'running') {
        return 'fa fa-running'
      }

      return defaultIcon
    })

    return {
      iconCssClass,
    }
  },
})
