export interface PartnerChallengeProductConfig {
  challengeID: number | null
  upwardProductID: string | null
  upwardColorTypeID: string | null
  upwardSizeTypeID: string | null
  isOptional: boolean
  optionalProductCost: number
}

export function getEmptyPartnerChallengeProductConfig() {
  return {
    challengeID: null,
    upwardProductID: null,
    upwardColorTypeID: 'N/A',
    upwardSizeTypeID: 'N/A',
    isOptional: false,
    optionalProductCost: 0.0,
  } as PartnerChallengeProductConfig
}
