










import { defineComponent, ref } from '@vue/composition-api'
import PageContent from '@/components/PageContent.vue'

export default defineComponent({
  name: 'ShippingTimelines',
  components: {
    PageContent,
  },
  setup() {
    const cmsDocumentId = ref('shippingTimelinesUpwardRunning')

    return {
      cmsDocumentId,
    }
  },
})
