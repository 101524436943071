






import { defineComponent, computed, ref, watch, onMounted, PropType, nextTick } from '@vue/composition-api'
import StartupOrderProductCardUR from './StartupOrderProductCardUR.vue'
import { getEmptyStartupOrderProductInfoList } from '@/lib/support/models/StartupOrderProductInfo'
import { sortOffering } from '@/services/offeringService'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import {
  PartnerChallengeStartupProductConfig,
  getEmptyPartnerChallengeStartupProductConfig,
} from '@/models/UpwardRunning/PartnerChallengeStartupProductConfig'
import { cloneDeep } from 'lodash'
import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'

export default defineComponent({
  name: 'StartupOrderPicker',
  components: {
    StartupOrderProductCardUR,
  },
  props: {
    products: {
      type: Array as PropType<StartupOrderProductInfo[]>,
      default: getEmptyStartupOrderProductInfoList,
      required: false,
    },
    values: {
      type: Array as PropType<PartnerChallengeStartupProductConfig[]>,
      default: () => [getEmptyPartnerChallengeStartupProductConfig()],
      required: false,
    },
  },
  setup(props) {
    const chosen = ref<PartnerChallengeStartupProductConfig[]>([])
    const logic = productFlowLogicUR()

    onMounted(() => {
      nextTick(() => watch(chosen.value, chosenChanged, { immediate: true }))
    })

    const sortedProducts = computed(() => sortOffering<StartupOrderProductInfo>(localClone.value))

    const localClone = computed(() => cloneDeep(props.products))

    function buildProductArray(): PartnerChallengeStartupProductConfig[] {
      const products = chosen.value.filter((x: PartnerChallengeStartupProductConfig) => x !== null)
      return products ?? ([] as PartnerChallengeStartupProductConfig[])
    }

    function chosenChanged() {
      logic.setStartupOrderProducts(buildProductArray())
      logic.markAsDirty()
    }

    return {
      sortedProducts,
      chosen,
    }
  },
})
