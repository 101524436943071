










import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import MinistryAgreementModal from '@/components/MinistryAgreementModal.vue'
import store from '@/store'

export default defineComponent({
  name: 'Setup',
  components: {
    VerticalTabs,
    Loading,
    Modal,
    MinistryAgreementModal,
  },
  setup(props, ctx) {
    const storeChallenge = computed(() => store.getters.upwardRunning.currentChallenge)
    const reloadMinistryAgreement = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const baseRoute = computed(() => `/programs/running/${routeId.value}setup`)

    const tabs = computed(() => {
      if (
        storeChallenge.value &&
        false // && !storeLeague.value.canEdit) {
      ) {
        return []
      }

      const retval = [
        { text: 'Program Information', to: `${baseRoute.value}/information` },
        { text: 'Key Dates', to: `${baseRoute.value}/dates` },
        { text: 'Director', to: `${baseRoute.value}/leadership` },
        { text: 'Build Participant Kit', to: `${baseRoute.value}/products` },
        { text: 'Online Registration', to: `${baseRoute.value}/registration` },
      ]

      return retval
    })

    onMounted(async () => {
      const archivedRoute = `${baseRoute.value}/archive`
      if (
        storeChallenge.value &&
        false && //&& !storeChallenge.value.canEdit
        ctx.root.$route.path !== archivedRoute
      ) {
        await ctx.root.$router.push(archivedRoute)
      } else {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      }
    })

    watch(
      () => storeChallenge.value.startDate,
      () => {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      },
      { deep: true }
    )

    return {
      tabs,
      reloadMinistryAgreement,
      storeChallenge,
    }
  },
})
