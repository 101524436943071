import { Vue, Component } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import * as runningStore from '@/store/upwardRunning'

import { Getter, Action, Mutation } from 'vuex-class'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import { RootContact } from '@/models/Partner/RootContact'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import * as promptToSaveStore from '@/store/promptToSave'

@Component({
  beforeRouteLeave(route, redirect, next) {
    const self = this as UpwardRunningSetupMixin

    self.onNavigation({
      save: async () => {
        return await self.save()
      },
      continue: () => {
        next()
      },
    } as PromptToSavePayload)
  },
})
export default class UpwardRunningSetupMixin extends Vue {
  protected challenge = {} as PartnerChallenge
  protected coachContact = {} as RootContact

  protected initialLoadDone = false
  protected loading = true
  protected nextRoute = ''
  protected beforeSave: null | (() => Promise<void | boolean>) = null
  protected afterUpdateExisting: null | (() => Promise<void> | void) = null
  protected afterSaveNew: null | (() => Promise<void>) = null

  @Getter(runningStore.getterNames.currentChallenge, { namespace: runningStore.namespace })
  protected readonly storeChallenge!: PartnerChallenge

  @Getter(runningStore.getterNames.currentPartnerContact, { namespace: runningStore.namespace })
  protected readonly storeContact!: RootContact

  @Action(runningStore.actionNames.cache, { namespace: runningStore.namespace })
  private readonly cache!: ({
    challenge,
    contact,
  }: {
    challenge: PartnerChallenge
    contact: RootContact
  }) => boolean

  @Action(runningStore.actionNames.validate, { namespace: runningStore.namespace })
  private readonly validate!: ({ item, ruleSet }: { item: PartnerChallenge; ruleSet: string }) => boolean

  @Action(runningStore.actionNames.loadFromCache, { namespace: runningStore.namespace })
  private readonly loadFromCache!: () => boolean

  @Action(runningStore.actionNames.update, { namespace: runningStore.namespace })
  private readonly update!: ({ challenge }: { challenge: PartnerChallenge }) => boolean

  @Mutation(promptToSaveStore.mutationNames.setIsDirty, { namespace: promptToSaveStore.namespace })
  protected readonly setIsDirty!: ({ isDirty }: { isDirty: boolean }) => void

  @Action(promptToSaveStore.actionNames.onNavigation, { namespace: promptToSaveStore.namespace })
  private readonly onNavigation!: ({}: PromptToSavePayload) => void

  private get ruleSet() {
    const pathParts = this.$route.path.split('/')

    if (!pathParts.length) {
      return ''
    }

    const thisPath = pathParts[pathParts.length - 1]

    switch (thisPath) {
      case 'information':
        return 'PROGRAMINFO'
      case 'dates':
        return 'KEYDATES'
      case 'leadership':
        return 'LEADERSHIP'
      case 'products':
        return 'PRICING'
      default:
        return ''
    }
  }

  protected async saveAndContinue() {
    const result = await this.save()
    if (result != false) this.continue()
  }

  protected async created() {
    this.challenge = { ...cloneDeep(this.storeChallenge) }
    this.coachContact = { ...cloneDeep(this.storeContact) }

    if (!this.challenge.typeLeagueID) {
      try {
        await this.loadFromCache()
      } catch (err) {
        this.$router.push('/programs/new/')
      }
    }

    this.loading = false
    this.initialLoadDone = true
  }

  protected async save() {
    if (this.beforeSave) {
      const r = await this.beforeSave()
      if (r === false) return false
    }

    if (this.challenge.upwardChallengeID) {
      await this.updateExistingChallenge()

      if (this.afterUpdateExisting) {
        await this.afterUpdateExisting()
      }
    } else {
      await this.saveNewChallenge()

      if (this.afterSaveNew) {
        await this.afterSaveNew()
      }
    }

    this.setIsDirty({ isDirty: false })
  }

  private continue() {
    if (this.nextRoute) {
      this.$router.push(this.nextRoute)
    }
  }

  private async saveNewChallenge() {
    if (this.challenge.upwardChallengeID) {
      return
    }

    this.loading = true
    try {
      await this.validate({ item: this.challenge, ruleSet: this.ruleSet })
      await this.cache({ challenge: this.challenge, contact: this.coachContact })
    } catch (err) {
      throw err
    } finally {
      this.loading = false
    }
  }

  private async updateExistingChallenge() {
    if (!this.challenge.upwardChallengeID) {
      return
    }

    this.loading = true
    try {
      await this.update({ challenge: this.challenge })
    } catch (err) {
      throw err
    } finally {
      this.loading = false
    }
  }

  mounted() {
    this.$nextTick(() => this.$watch('challenge', this.onChallengeChange, { deep: true }))
  }

  private onChallengeChange() {
    this.setIsDirty({ isDirty: true })
  }
}
