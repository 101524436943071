
























import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import Loading from '@/elements/Loading.vue'
import ContactForm from '@/components/ContactForm.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import { RootContact } from '@/models/Partner/RootContact'
import SaveContinueButtonUR from '@/elements/UpwardRunning/SaveContinueButtonUR.vue'
import { cloneDeep } from 'lodash'
import * as runningStore from '@/store/upwardRunning'
import partnersClient from '@/clients/partnersClient'

@Component({
  components: {
    ContactForm,
    Loading,
    SaveContinueButtonUR,
  },
})
export default class Leadership extends Mixins(UpwardRunningSetupMixin) {
  @Mutation(runningStore.mutationNames.setCurrentPartnerContact, { namespace: runningStore.namespace })
  protected readonly setCurrentPartnerContact!: ({ contact }: { contact: RootContact }) => void

  mounted() {
    this.afterUpdateExisting = this.afterUpdateExistingMethod
    this.beforeSave = this.beforeSaveMethod

    if (this.challenge.upwardChallengeID) {
      this.nextRoute = `/programs/running/${this.challenge.upwardChallengeID}/setup/products`
    } else {
      this.nextRoute = '/programs/running/setup/products'
    }
  }

  private async onSubmit() {
    await this.save()
  }

  private async beforeSaveMethod() {
    //validate teh contact info
    if ((this.coachContact.id ?? 0) == 0) {
      await partnersClient.validateNew(this.challenge.accountNumber ?? '', this.coachContact)
    } else {
      await partnersClient.validateExisting(
        this.challenge.accountNumber ?? '',
        this.coachContact.id!,
        this.coachContact
      )
    }
  }

  private async afterUpdateExistingMethod() {
    await partnersClient.updateAccountContact(
      this.challenge.accountNumber ?? '',
      this.coachContact.id ?? 0,
      this.coachContact
    )
    this.setCurrentPartnerContact({ contact: this.coachContact })
    this.challenge = { ...cloneDeep(this.storeChallenge) }
  }
}
