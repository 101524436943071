import restService from '@/services/restService'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import {
  CFormatOrder,
  xRunningStartupOrderExt,
  OrderLineRollupInfo,
} from '@/models/UpwardRunning/Order/xRunningStartupOrderExt'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { xRunningStartupOrder } from '@/GeneratedTypes/xOrder/xRunningStartupOrder'
import { ToXRunningStartupOrder } from '@/lib/support/models/GeneratedTypes/xOrders/xRunningStartupOrderExt'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

const baseUrl = 'orders/runningOrder/'

class RunningOrderClientException extends GeneralError {
  name = 'Order Client API Error'
}

const getRunningOrderProductList = async (typeLeagueID: string, startDate: Date) => {
  let dt: string | null = null
  dt = convertToLocalDateString(startDate)
  if (!dt) throw new Error('upwardRunningOrderClient.getRunningOrderProductList: missing startDate')
  const products = await restService.get<RunningOrderProductInfo[]>(
    `${baseUrl}products/${typeLeagueID}/${dt}`
  )
  if (products.isSuccess) {
    return products.data ?? ([] as RunningOrderProductInfo[])
  }

  throw new RunningOrderClientException('Trouble pending return products')
}

const verificationDetails = async (id: string, order: xRunningStartupOrderExt) => {
  const convertedOrder = ToXRunningStartupOrder(order)
  const results = await restService.post<UpwardVerificationDetails>(
    `${baseUrl}${id}/verificationDetails`,
    convertedOrder
  )
  if (results.isSuccess) {
    return results.data?.model as xRunningStartupOrder
  } else {
    throw new RunningOrderClientException('Error in upwardRunningOrderClient.verificationDetails')
  }
}

const createOrder = async (id: string, order: xRunningStartupOrderExt) => {
  const convertedOrder = ToXRunningStartupOrder(order)
  const results = await restService.post<xRunningStartupOrder>(`${baseUrl}${id}`, convertedOrder)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new RunningOrderClientException('Error in upwardRunningOrderClient.createOrder')
  }
}

const getOrder = async ({ challengeID, id }: { challengeID: string; id: string }) => {
  const url = `${baseUrl}${challengeID}/${id}`
  const results = await restService.get<CFormatOrder>(url, { format: 'c' })
  if (results.isSuccess) {
    return results.data
  }
  throw new RunningOrderClientException(`could not gather order information for ${challengeID} and ${id}`)
}

const getOrderRollup = async ({ challengeID, id }: { challengeID: string; id: string }) => {
  const url = `${baseUrl}${challengeID}/${id}`
  const results = await restService.get<OrderLineRollupInfo[]>(url, { format: 'r' })
  if (results.isSuccess) {
    return results.data
  }
  throw new RunningOrderClientException(`could not gather order information for ${challengeID} and ${id}`)
}

const getOrderInfo = async ({ challengeID, id }: { challengeID: string; id: string }) => {
  const url = `${baseUrl}${challengeID}/${id}`
  const results = await restService.get<OrderInfoExt>(url, { format: 'i' })
  if (results.isSuccess) {
    return results.data
  }
  throw new RunningOrderClientException(`could not gather order information for ${challengeID} and ${id}`)
}

const getOrderList = async (id: string) => {
  const results = await restService.get<xRunningStartupOrder[]>(`${baseUrl}${id}`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new RunningOrderClientException('Error in upwardRunningOrderClient.getOrderList')
  }
}

const retrieveTemplate = async (id: string, orderType: OrderTypesEnum) => {
  const results = await restService.get<xRunningStartupOrderExt>(`${baseUrl}${id}/new`, {
    upwardOrderTypeID: orderType.toUpperCase(),
  })
  if (results.isSuccess) {
    return results.data
  } else {
    throw new RunningOrderClientException('Error in upwardRunningOrderClient.retrieveTemplate')
  }
}

const getRunningOrderItems = async (challengeID: string) => {
  const t = await restService.get<RunningOrderProductInfo[]>(`${baseUrl}${challengeID}/products`, {
    upwardOrderTypeID: 'RUNNINGORDER',
  })
  if (t.isSuccess) {
    return t.data
  }
  throw new RunningOrderClientException('could not gather challenge items required by participant')
}

function convertToLocalDateString(dt: string | Date | null): string | null {
  if (dt) {
    if (dt instanceof Date) {
      dt = dt
    } else {
      dt = new Date(dt)
    }
    const month = dt.getMonth() + 1
    const monthStr = month < 10 ? `0${month}` : month
    const day = dt.getDate()
    const dayStr = day < 10 ? `0${day}` : day
    const dateStr = `${dt.getFullYear()}-${monthStr}-${dayStr}`
    return dateStr
  } else {
    return null
  }
}

export default {
  getRunningOrderProductList,
  verificationDetails,
  createOrder,
  getOrder,
  getOrderRollup,
  getOrderInfo,
  getOrderList,
  retrieveTemplate,
  getRunningOrderItems,
}
