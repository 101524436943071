

































































import { cloneDeep } from 'lodash'
import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import SetupStepperRunning from '@/components/UpwardRunning/ProductFlow/SetupStepperRunning.vue'
import RunningRegistrationFee from '@/components/UpwardRunning/ProductFlow/RunningRegistrationFee.vue'
import SaveContinueButtonUR from '@/elements/UpwardRunning/SaveContinueButtonUR.vue'
import ProductWarningModal from '@/views/Leagues/ProductWarningModal.vue'
import CurrencyWarning from '@/components/ProductFlow/ProductSetupStepper/CurrencyWarning.vue'
import Loading from '@/elements/Loading.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ValidationError } from 'src/models/UpwardVerificationDetails'

import * as promptToSave from '@/store/promptToSave'
import store from '@/store'

import upwardRunningClient from '@/clients/upwardRunningClient'

import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'

@Component({
  components: {
    SetupStepperRunning,
    RunningRegistrationFee,
    SaveContinueButtonUR,
    ProductWarningModal,
    CurrencyWarning,
    Loading,
  },
})
export default class Products extends Mixins(UpwardRunningSetupMixin) {
  @Getter(promptToSave.getterNames.isDirty, { namespace: promptToSave.namespace })
  private readonly isDirty!: boolean

  private modalIsVisible = false
  private userWantsToIgnoreWarnings = false
  private warnings: ValidationError[] | null = null
  private showWarnings = false

  logic = productFlowLogicUR()

  async mounted() {
    if (this.challenge.upwardChallengeID) {
      this.nextRoute = `/programs/running/${this.challenge.upwardChallengeID}/setup/registration`
    } else {
      this.nextRoute = '/programs/running/setup/registration'
    }
    this.beforeSave = this.productBeforeSave
    await this.logic.fetchProductCatalog() //go ahead and get these cached up
  }

  get initialProductFlowLoadingComplete() {
    return store.getters.productOfferings.initialProductFlowLoadingComplete
  }

  get showCurrencyWarning() {
    if (store.getters.partners.currencyWarningNeeded && this.logic.isNewProdConfig.value) {
      return !store.getters.partners.hasAcceptedCurrencyWarning
    }
    return false
  }

  get isLoading() {
    if (this.logic.isNewProdConfig.value) {
      return this.loading
    } else {
      return !this.initialProductFlowLoadingComplete
    }
  }

  private get hasEarlyRegistration() {
    const isDate =
      this.challenge.earlyRegistrationEndDate instanceof Date &&
      !isNaN(this.challenge.earlyRegistrationEndDate.valueOf())

    if (isDate) {
      const date = this.challenge.earlyRegistrationEndDate as Date
      return date !== null
    }

    return this.challenge.earlyRegistrationEndDate !== null
  }

  get selectedProductOffering() {
    return store.getters.productOfferings.selectedProductOfferingConfig
  }

  private async productBeforeSave() {
    //this.league.productOfferingConfig = this.selectedProductOffering
    //this.league.productOfferingConfig.accountNumber = null

    /*****************************************************************
     ** If this is a new league, or the user wants to ignore warnings
     ** skip validation and return true
     *****************************************************************/

    //everything in this step has been sent to the store so refresh from there
    this.challenge = store.getters.upwardRunning.currentChallenge

    if (
      this.userWantsToIgnoreWarnings ||
      this.logic.isNewProdConfig.value ||
      !this.challenge.upwardChallengeID
    ) {
      this.userWantsToIgnoreWarnings = false
      return true
    }

    //Validation only used for warnings. Broken rules handled in the update call
    const result = await upwardRunningClient.validateExisting(
      this.challenge.upwardChallengeID ?? '',
      cloneDeep(this.challenge)
    )
    this.warnings = result.data?.warnings ?? null
    return result && result.data ? result.data?.warnings.length < 1 : true
  }

  /*
   * Calculate saveDisabled
   */

  get maxTouchedParticipantKitStep() {
    return store.getters.urProductFlow.maxTouchedParticipantKitStep
  }
  get saveDisabled() {
    return this.maxTouchedParticipantKitStep >= 3 //TODO determine what this needs to be
  }

  private onModalStateChange() {
    this.$router.push(`/programs/running/${this.challenge.upwardChallengeID}/setup/information`)
  }

  ignoreWarnings() {
    this.userWantsToIgnoreWarnings = true
    this.saveAndContinue()
  }

  @Watch('isDirty')
  dirtyChanged() {
    if (!this.isDirty) this.userWantsToIgnoreWarnings = false
  }
  @Watch('warnings')
  warningsChanged() {
    this.showWarnings = this.warnings ? this.warnings.length > 0 : false
  }
}
