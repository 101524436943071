














import { defineComponent, computed, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import { Resource } from '@/models/Program/Resource'
import resourceUsageClient from '@/clients/resourceUsageClient'
import { ResourceItem } from '@/models/Program/ResourceItem'
import resourceService from '@/services/resourceService'
import store from '@/store'

export default defineComponent({
  name: 'ResourceTile',
  components: {
    VerticalTabs,
    Loading,
    TextInput,
    SelectInput,
  },
  props: {
    resource: { type: Object as PropType<Resource>, required: true },
    roleDescription: { type: String, required: true },
  },
  setup(props) {
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const userEmail = computed(() => store.getters.authorization.email)

    const iconCssClass = computed(() => {
      const fileType = resourceService.getResourceFileType(props.resource.resourceUrl)
      return resourceService.getResourceFileTypeIcon(fileType)
    })

    function downloadResource() {
      const resourceItem: ResourceItem = {
        accountNumber: firstAccountNumber.value,
        resourceID: props.resource.resourceID,
        resourceTitle: props.resource.title,
        programType: 'NONE',
        userID: userEmail.value,
        role: props.roleDescription,
        pageAccessedFrom: window.location.href,
      }

      resourceUsageClient.postResourceUsage(resourceItem)

      if (props.resource.mediaUrl) {
        window.open(props.resource.mediaUrl, '_blank')
      }

      if (props.resource.resourceUrl) {
        window.open(props.resource.resourceUrl, '_blank')
      }
    }

    return {
      downloadResource,
      iconCssClass,
    }
  },
})
