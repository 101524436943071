





























































import uuid from 'uuid'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import EditDiscountCodeUR from '@/components/UpwardRunning/EditDiscountCodeUR.vue'
import { PartnerChallengeDiscountCode } from '@/models/UpwardRunning/PartnerChallengeDiscountCode'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import { SaveIcon } from '@/elements/Icons'

@Component({
  components: {
    Modal,
    TextInput,
    EditDiscountCodeUR,
    SimpleGrid,
    SaveIcon,
  },
})
export default class ManageDiscountCodesUR extends Vue {
  private readonly guid = uuid.v4()
  private modalIsVisible = false
  private modalTitle = 'Manage Discount Codes'
  private defaultManageButtonTitle = 'Manage Discount Codes'
  private manageButtonTitle = this.defaultManageButtonTitle
  private wasCancelled = false
  private showEditScreen = false
  private activeDiscountCode: PartnerChallengeDiscountCode | null = null

  public get gridId() {
    return `grid-${this.guid}`
  }

  @Prop({ required: true })
  private readonly discountCodes!: PartnerChallengeDiscountCode[] | null

  @Prop({ type: Number, required: true, default: 0 })
  private readonly challengeId!: number

  private gridColumns: GridColumn[] = [
    {
      field: 'description',
      headerText: 'Description',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'discountCode',
      headerText: 'Code',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'discountAmount',
      headerText: 'Amount',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'maxUsages',
      headerText: 'Max Usages',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'numberOfUsages',
      headerText: '# of Usages',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'active',
      headerText: 'Active',
      textAlign: '',
      width: 0,
      format: '',
      customRender: this.renderActive,
    } as GridColumn,
  ]

  private internalDiscountCodes: PartnerChallengeDiscountCode[] = []

  @Watch('modalIsVisible')
  private onModalVisibilityChanged() {
    this.wasCancelled = false
  }

  @Watch('internalDiscountCodes.length')
  private oninternalDiscountCodesChanged() {
    this.manageButtonTitle = `${this.defaultManageButtonTitle} ${
      this.internalDiscountCodes.length > 0 ? '(' + this.internalDiscountCodes.length + ')' : ''
    }`
  }

  private done() {
    this.$emit('onSave', this.discountCodes)
    this.toggleModal()
  }

  private cancel() {
    this.wasCancelled = true
    this.toggleModal()
  }

  private toggleModal() {
    this.modalIsVisible = !this.modalIsVisible
  }

  private mounted() {
    if (this.discountCodes) {
      this.internalDiscountCodes = this.discountCodes
    } else {
      this.internalDiscountCodes = []
    }
  }

  private saveDiscountCode(discountCode: PartnerChallengeDiscountCode) {
    if (discountCode.id === 0) {
      discountCode.id = -1 * (this.discountCodes!.length + 1)
      this.discountCodes?.push(discountCode)
      this.internalDiscountCodes = this.discountCodes!
    }

    this.activeDiscountCode = null
  }

  private handleEditBottonClicked(entity: any) {
    this.activeDiscountCode = entity
    this.showEditScreen = true
  }

  private handleDeleteBottonClicked(entity: any) {
    const index = this.internalDiscountCodes.indexOf(entity)
    if (index < 0) {
      return
    }

    this.internalDiscountCodes.splice(index, 1)
  }

  private renderActive(value: any) {
    if (value) {
      return '<div class="active-dot"></div>'
    }
    return '<div class="inactive-dot"></div>'
  }
}
