import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import { ProductOfferingProductColorInfo } from 'src/GeneratedTypes/ListInfo/ProductOfferingProductColorInfo'

export interface RunningOrderProductInfoEXT extends RunningOrderProductInfo {
  colors: ProductOfferingProductColorInfo[]
}

export function getRunningOrderProductInfoExt(product: RunningOrderProductInfo) {
  const retval = product as RunningOrderProductInfoEXT
  retval.colors = []
  product.productColorSizes?.forEach((color) => {
    if (
      color.colorActive &&
      color.typeColorID != 'N/A' &&
      retval.colors.findIndex((retColor) => retColor.typeColorID == color.typeColorID) < 0
    ) {
      retval.colors.push({
        typeColorID: color.typeColorID,
        active: true,
        sortOrder: color.colorSortOrder,
        costOffset: color.colorCostOffset,
      })
    }
  })
  return retval
}
