


















import { defineComponent, computed } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'
import ProductPickerUR from '@/components/UpwardRunning/ProductFlow/ProductPickerUR.vue'
import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'
import { getRunningOrderProductInfoExt } from '@/models/UpwardRunning/RunningOrderProductInfoEXT'
import store from '@/store'

export default defineComponent({
  name: 'ParticipantKitStep',
  props: {
    step: { type: Number, required: true },
    stepTitle: { type: String, required: true },
  },
  components: { Step, ProductPickerUR },
  setup() {
    const logic = productFlowLogicUR()
    const availableProducts = computed(() =>
      store.getters.upwardRunning.runningOrderProducts
        .filter((fp) => fp.parentProductID == 0)
        .map((product) => getRunningOrderProductInfoExt(product))
    )

    return { ...logic, availableProducts }
  },
})
