import { PartnerChallengeProductConfig } from './PartnerChallengeProductConfig'
import { PartnerChallengeStartupProductConfig } from './PartnerChallengeStartupProductConfig'
import { PartnerChallengeUDFDefinition } from './PartnerChallengeUDFDefinition'
import { PartnerChallengeDiscountCode } from './PartnerChallengeDiscountCode'

export interface PartnerChallenge {
  id: number | null
  challengeName: string | null
  upwardChallengeID: string | null
  accountNumber: string | null
  coachPartnerContactID: number | null
  typeTimezoneID: string | null
  classDay: string | null
  classTime: string | null
  missionObjective: string | null
  registrationBeginDate: Date | null
  registrationEndDate: Date | null
  registrationFee: number | null
  earlyRegistrationEndDate: Date | null
  earlyRegistrationFee: number | null
  registrationStartMessage: string | null
  confirmationMessage: string | null
  devotionTrackCMSID: string | null
  hideFromLeagueFinder: boolean
  leagueFinderMessage: string | null
  participantEstimate: number | null
  productConfig: PartnerChallengeProductConfig[]
  startupProductConfig: PartnerChallengeStartupProductConfig[]
  udfs: PartnerChallengeUDFDefinition[]
  discountCodes: PartnerChallengeDiscountCode[]
  //inheritted from Challenge base table
  startDate: Date | null
  publicDisplayName: string | null
  contactPhone: string | null
  contactEmail: string | null
  //needed to choose the challenge type in UR
  typeLeagueID: string | null
}

export function getEmptyPartnerChallenge(): PartnerChallenge {
  return {
    id: 0,
    challengeName: null,
    upwardChallengeID: null,
    typeTimezoneID: null,
    accountNumber: null,
    coachPartnerContactID: 0,
    classDay: null,
    classTime: '',
    missionObjective: '',
    registrationBeginDate: null,
    registrationEndDate: null,
    registrationFee: 0,
    earlyRegistrationEndDate: null,
    earlyRegistrationFee: 0,
    registrationStartMessage: null,
    confirmationMessage: null,
    devotionTrackCMSID: null,
    hideFromLeagueFinder: false,
    leagueFinderMessage: '',
    participantEstimate: 0,
    productConfig: [],
    startupProductConfig: [],
    udfs: [],
    discountCodes: [],
    startDate: null,
    publicDisplayName: null,
    contactPhone: null,
    contactEmail: null,
    typeLeagueID: null,
  }
}
