











import { defineComponent, PropType } from '@vue/composition-api'
import { UpwardRunningCache } from '@/store/upwardRunning'

export default defineComponent({
  name: 'ResumeURProgram',
  props: {
    programCache: { type: Object as PropType<UpwardRunningCache>, required: true },
  },
  components: {},
  setup(props, { emit }) {
    function clicked() {
      emit('click', 'RUNNING')
    }

    function remove() {
      emit('remove')
    }
    return { remove, clicked }
  },
})
