





























import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import ordersClient, { ValidateLeagueOrderShippingPayload } from '@/clients/ordersClient'
import store from '@/store'
import { componentState, stateIsLoaded } from '@/views/Programs/Orders/league/lib/LeagueOrderSM'
import StepParticipantMissingSizes from '@/views/Programs/Orders/league/steps/StepParticipantMissingSizes.vue'
import StepIntro from '@/views/Programs/Orders/league/steps/StepIntro.vue'
import StepSelectAddOnItems from '@/views/Programs/Orders/league/steps/StepSelectAddOnItems.vue'
import StepReviewParticipantDetail from '@/views/Programs/Orders/league/steps/StepReviewParticipantDetail.vue'
import StepParticipantDetailPreference from '@/views/Programs/Orders/league/steps/StepParticipantDetailPreference.vue'
import { LOSStates, SizeUpdatedPayload } from '@/lib/support/store/leagueOrder/leagueOrderState'
import StepConfirm from '@/views/Programs/Orders/league/steps/StepConfirm.vue'
import StepReview from '@/views/Programs/Orders/league/steps/StepReview.vue'
import StepShipping from '@/views/Programs/Orders/league/steps/StepShipping.vue'
import { cloneDeep } from 'lodash'
import { OrderProductLine } from '@/models/Order/OrderProduct'
import Vue from 'vue'
import StepParticipantExchange from '@/views/Programs/Orders/league/steps/StepParticipantExchange.vue'
import StepCoachExchange from '@/views/Programs/Orders/league/steps/StepCoachExchange.vue'
import Loading from '@/elements/Loading.vue'
import StepNewParticipants from '@/views/Programs/Orders/league/steps/StepNewParticipants.vue'
import StepNewCoaches from '@/views/Programs/Orders/league/steps/StepNewCoaches.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import DoNotOrderWarningModal from '@/views/Programs/Orders/vues/DoNotOrderWarningModal.vue'
import SkipThisOrderWarningModal from '@/views/Programs/Orders/vues/SkipThisOrderWarningModal.vue'

export default defineComponent({
  name: 'OrderLeague',
  components: {
    Loading,
    StepIntro,
    StepParticipantMissingSizes,
    StepNewParticipants,
    AlertCloak,
    StepParticipantDetailPreference,
    StepReviewParticipantDetail,
    StepSelectAddOnItems,
    StepReview,
    StepShipping,
    StepConfirm,
    StepParticipantExchange,
    StepCoachExchange,
    StepNewCoaches,
    DoNotOrderWarningModal,
    SkipThisOrderWarningModal,
  },
  setup(props, ctx) {
    const itemList = ref<ParticipantOrderProductInfo[]>([])
    const league = computed(() => store.getters.leagueAbstraction.currentItem)
    const state = computed(() => store.getters.leagueOrders.root)
    const readyToGo = ref(false)
    //** are we already resetting the order?
    const resetting = ref(false)
    const orderConfirmed = ref(false)
    /**
     * Go to the beginning, set the step to first step.
     */
    async function resetOrder() {
      if (!resetting.value) {
        resetting.value = true
        orderConfirmed.value = false
        await store.commit.leagueOrders.setCurrentStep({
          currentProgram: '',
          currentStep: LOSStates.PRE_STEP,
        })
        Vue.nextTick(async () => {
          try {
            itemList.value =
              (await ordersClient.getLeagueOrderItems(league.value?.upwardLeagueID ?? '')) ?? []
            const programs = league.value.programs?.map((x) => x.typeProgramID) ?? []

            await store.dispatch.leagueOrders.resetOrder({
              league: league.value.upwardLeagueID ?? '',
              programs,
            })
          } finally {
            readyToGo.value = true
            resetting.value = false
          }
        })
      }
    }

    /**
     * Route change watch for back button. stepping before the first state is invalid, and league change on the url will reload the entire order
     */
    watch(
      () => ctx.root.$route,
      async () => {
        if (
          orderConfirmed.value ||
          parseInt(ctx.root.$route.params?.step ?? LOSStates.PRE_STEP) === LOSStates.PRE_STEP ||
          !stateIsLoaded(state.value)
        ) {
          await resetOrder()
        }

        if (
          // we may need to load state
          stateIsLoaded(state.value) &&
          // we want to make sure we aren't getting the same step we are on.
          state.value.currentStep != parseInt(ctx.root.$route.params?.step ?? LOSStates.PRE_STEP)
        ) {
          const leagueid = ctx.root.$route.params?.id ?? ''

          // make sure league id on URL matches our league state.
          if (leagueid != state.value?.template.upwardLeagueID) {
            await resetOrder()
          } else {
            store.commit.leagueOrders.setCurrentStep({
              currentProgram: '',
              currentStep: parseInt(ctx.root.$route.params?.step ?? LOSStates.PRE_STEP),
            })
          }
        }
      },
      { immediate: true }
    )

    watch(
      () => league.value,
      async () => {
        if (league.value && league.value.upwardLeagueID) {
          await resetOrder()
        }
      },
      { immediate: true }
    )

    function setCurrentStep(payload: { currentProgram: string; currentStep: LOSStates }) {
      if (payload.currentStep.toString() != ctx.root.$route.params?.step ?? LOSStates.PRE_STEP) {
        ctx.root.$router.push({
          params: { ...ctx.root.$route.params, step: payload.currentStep.toString() },
        })
      }
      store.commit.leagueOrders.setCurrentStep(payload)
    }
    async function sizingUpdate(payload: SizeUpdatedPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })

      await store.dispatch.leagueOrders.sizingUpdate(updatedPayload)
    }
    async function validate(payload: ValidateLeagueOrderShippingPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })

      await store.dispatch.leagueOrders.validateOrder(updatedPayload)
    }

    async function confirmOrder(payload: ValidateLeagueOrderShippingPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })

      try {
        await store.dispatch.leagueOrders.confirmOrder(updatedPayload)
        orderConfirmed.value = true
      } catch (e) {
        throw e
      }
    }

    async function addToExchange(payload: SizeUpdatedPayload) {
      await store.dispatch.leagueOrders.addToExchange({
        ...payload,
        product: { ...payload.product, quantity: 1 },
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })
    }

    async function removeFromExchange(payload: SizeUpdatedPayload) {
      await store.dispatch.leagueOrders.removeFromExchange({
        ...payload,
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })
    }

    async function addProduct(payload: OrderProductLine) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardLeagueID: league.value.upwardLeagueID ?? '',
        leagueID: league.value.id,
      })

      await store.dispatch.leagueOrders.addProduct({
        league: state.value.template.upwardLeagueID ?? '',

        leagueID: league.value.id,
        account: state.value.template.accountNumber ?? '0',
        product: updatedPayload,
      })
    }
    async function removeProduct(payload: OrderProductLine) {
      const updatedPayload = cloneDeep({
        ...payload,
        orderQuantity: 0,
        productColorSize: { ...payload.productColorSize, orderQuantity: 0 },
      } as OrderProductLine)

      await store.dispatch.leagueOrders.addProduct({
        league: state.value.template.upwardLeagueID ?? '',

        leagueID: league.value.id,
        account: state.value.template.accountNumber ?? '0',
        product: updatedPayload,
      })
    }

    function commitSizing(payload: any) {
      store.commit.leagueOrders.setProductSize(payload)
    }

    onMounted(() => (readyToGo.value = false))
    // reset order on navigate away
    onUnmounted(() => {
      store.commit.leagueOrders.eraseState()
    })
    const currentComponent = computed(() => componentState.find((x) => x.step == state.value.currentStep))
    return {
      itemList,
      league,
      currentComponent,
      state,
      addToExchange,
      commitSizing,
      removeFromExchange,
      setCurrentStep,
      sizingUpdate,
      validate,
      addProduct,
      confirmOrder,
      removeProduct,
      readyToGo,
      alertTypes: [AlertCloakTypes.SALES_TAX],
    }
  },
})
