import { DataTableHeader } from 'vuetify/types'
export const headers: DataTableHeader[] = [
  {
    text: 'Name',
    value: 'prettyName',
    sort: (a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    },
  },
  { text: 'Complete', value: 'registrationCompletionInformation' },
  { text: 'Sizing', value: 'isPlayerSizingComplete' },
  { text: 'Owed', value: 'prettyOwed' },
  { text: 'Paid', value: 'prettyPaid' },
  { text: 'Gender', value: 'gender' },
  { text: 'Grade', value: 'typeGradeID' },
  { text: 'Score', value: 'evaluationScore' },
  { text: '', value: 'actions' },
]

export const headersByAge: DataTableHeader[] = [
  { text: 'Name', value: 'prettyName' },
  { text: 'Complete', value: 'registrationCompletionInformation' },
  { text: 'Sizing', value: 'isPlayerSizingComplete' },
  { text: 'Owed', value: 'prettyOwed' },
  { text: 'Paid', value: 'prettyPaid' },
  { text: 'Gender', value: 'gender' },
  { text: 'Age', value: 'ageByCutoff' },
  { text: 'Score', value: 'evaluationScore' },
  { text: '', value: 'actions' },
]

export const headersRemovedParticipants: DataTableHeader[] = [
  { text: 'Name', value: 'prettyName' },
  { text: 'Paid', value: 'prettyPaid' },
  { text: 'Gender', value: 'gender' },
  { text: 'Grade', value: 'typeGradeID' },
  { text: '', value: 'actions' },
]
