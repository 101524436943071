
























































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import Loading from '@/elements/Loading.vue'

import date from '@/filters/date'
import properCase from '@/filters/properCase'
import currency from '@/filters/currency'
import { formatPhoneNumber } from '@/services/phoneNumberService'

import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import { PartnerChallengeProductConfig } from '@/models/UpwardRunning/PartnerChallengeProductConfig'
import dayjs from 'dayjs'
import store from '@/store'

@Component({
  components: {
    Loading,
  },
  filters: {
    date,
    properCase,
    currency,
  },
  methods: {
    formatPhoneNumber,
    date,
  },
})
export default class Archive extends Mixins(UpwardRunningSetupMixin) {
  private get hasEarlyRegistration(): boolean {
    return (
      this.challenge.earlyRegistrationEndDate !== null &&
      !dayjs('0001-01-01T00:00:00').isSame(dayjs(this.challenge.earlyRegistrationEndDate ?? ''))
    )
  }

  async mounted() {
    const challenge = store.getters.upwardRunning.currentChallenge
    await store.dispatch.upwardRunning.loadRunningOrderProducts({
      typeLeagueID: challenge.typeLeagueID ?? '',
      startDate: challenge.startDate ?? new Date(),
    })
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    this.getProducts()
  }

  productsLoading = false
  productList: (PartnerChallengeProductConfig | null)[] = []
  getProducts() {
    /*
    //get all product offering ids across all league types
    const offeringIds = [
      ...new Set(this.currentLeague.productOfferingConfig?.details?.map((p) => p.productOfferingID)),
    ]

    //build and execute api calls; one call for each offeringId
    const calls: Promise<ProductOfferingInfo | null>[] = []
    offeringIds.forEach((id: number) => calls.push(offeringClient.retrieveChildOfferings(id)))

    this.productsLoading = true
    const results = await Promise.all(calls)
    this.productsLoading = false

    //get all product ids across all league types
    const selProducts = this.currentLeague.productOfferingConfig?.details?.map((p) => {
      return { productOfferingID: p.productOfferingID, productID: p.productID }
    })

    //get list of selected products
    // this.productList = this.filterToSelected(results, selProducts)
    */
    this.productList = this.challenge.productConfig
  }

  filterToSelected(
    results: (ProductOfferingInfo | null)[],
    selProducts: { productOfferingID: number; productID: number }[] | undefined
  ) {
    if (!selProducts) return []

    //remove fees
    results.forEach((r) => {
      if (r && r.categories?.length) {
        r.categories = r.categories.filter(
          (c) =>
            c.typeProductOfferingOptionID != 'UPWARDFEES' && c.typeProductOfferingOptionID != 'UPWARDDISCOUNT'
        )
      }
    })

    //filter catalog to only those products selected by the league
    results.forEach((r) => {
      r?.categories?.forEach((c) => {
        const p = c.products?.filter((p) =>
          selProducts.some((s) => s.productOfferingID == r.id && s.productID == p.productID)
        )
        c.products = p ?? null
      })
    })

    return results
  }

  getProductDescription(upwardProductID: string | null) {
    //get list of products from store
    const products = store.getters.upwardRunning.runningOrderProducts
    //find upwardProductID in list of products
    const product = products.find((x) => x.upwardProductID == upwardProductID)
    //return productName or registerLabel or upwardProductID
    if (product) {
      if (product.cmsProductName) {
        return product.cmsProductName
      }
      if (product.registrationLabel) {
        return product.registrationLabel
      }
    }
    return upwardProductID
  }
}
