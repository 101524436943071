









import { defineComponent, computed, PropType } from '@vue/composition-api'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  name: 'SaveContinueButtonUR',
  components: {
    Loading,
  },
  props: {
    program: { type: Object as PropType<PartnerChallenge>, required: true },
    loading: { type: Boolean, required: true },
    showSave: { type: Boolean, required: false, default: false },
    disable: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    function saveAndContinue(args: any) {
      ctx.emit('click', args)
    }

    const buttonDisabled = computed(() => props.loading || props.disable)

    return {
      saveAndContinue,
      buttonDisabled,
    }
  },
})
