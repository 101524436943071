










































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import uuid from 'uuid'

import InputLabel from '@/elements/InputLabel.vue'
import { ValidationProvider } from 'vee-validate'
import { VueConstructor } from 'vue'

/// <reference path="@/modules/vuejs-datepicker.d.ts" />
import Datepicker from 'vuejs-datepicker'
import { getDateWithTime } from '@/lib/support/utilities/time/time'

export default defineComponent({
  name: 'DateInput',
  components: {
    InputLabel,
    ValidationProvider,
    Datepicker: (Datepicker as unknown) as VueConstructor,
  },
  props: {
    VeeValidateRules: { type: String, default: '', required: false },
    veeValidateMode: { type: String, default: 'aggressive', required: false },
    validationProviderName: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    strictMode: { type: Boolean, default: false, required: false },
    label: { type: String, default: '', required: false },
    placeholder: { type: String, default: '', required: false },
    showHelp: { type: Boolean, default: false, required: false },
    displayCalendarTop: { type: Boolean, default: false },
    noBottomPadding: { type: Boolean, default: false, required: false },
    isTypeable: { type: Boolean, default: true, required: false },
    disabled: { type: Boolean, default: false, required: false },
    // prop: time
    // format = HH:mm:ss
    // The date component is really just for setting dates, but it carries with it the time the user
    // set the date. If you need the time to be something else, like the begining or end of the day,
    // use the time prop.
    time: { type: String, default: '', required: false },
    minDate: {
      default: () => {
        const d = new Date()
        d.setDate(d.getDate() - 90)
        return d
      },
      required: false,
    },
    maxDate: {
      default: () => {
        const d = new Date()
        d.setDate(d.getDate() + 730)
        return d
      },
      required: false,
    },
    value: { type: (null as unknown) as PropType<Date | string | null>, required: true },
    disabledWeekDays: {
      type: (null as unknown) as PropType<number[] | null>,
      required: false,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const internalValue = ref<Date | null>(null)
    const inputId = ref('')
    const once = ref(false)

    function setInternalValue() {
      if (!props.value) {
        internalValue.value = null
      } else if (typeof props.value === 'string') {
        internalValue.value = new Date(props.value)
      } else {
        internalValue.value = props.value
      }
    }

    //private created()
    setInternalValue()
    inputId.value = 'datepicker-' + uuid.v4()

    const disabledDates = computed(() => {
      let min = props.minDate ? new Date(props.minDate.valueOf()) : null

      if (min) {
        min.setDate(min.getDate() - 1)
      }

      return {
        to: min,
        from: props.maxDate,
        days: props.disabledWeekDays,
      }
    })

    function onIconClick() {
      const input = (ctx.refs.datepicker as Vue).$el.querySelectorAll('input')[0]
      input.click()
      input.focus()
    }

    function handleChange() {
      if (props.time && internalValue.value) {
        internalValue.value = setTime(internalValue.value)
      }
      ctx.emit('input', internalValue.value)
    }

    function setTime(date: Date) {
      if (props.time && date) {
        const parts = props.time.split(':')
        return getDateWithTime(date, Number(parts[0]), Number(parts[1]), Number(parts[2]))
      }
      return date
    }

    watch(
      () => props.value,
      () => {
        setInternalValue()
        if (props.time && !once.value) {
          handleChange()
          once.value = true
        }
      },
      { immediate: true }
    )

    return {
      inputId,
      internalValue,
      disabledDates,
      handleChange,
      onIconClick,
    }
  },
})
