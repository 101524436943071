
















































































































































import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import * as authorizationStore from '@/store/authorization'
import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import DateTimeInput from '@/elements/DateTimeInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import EFTAuthorizationRequestModal from '@/components/EFTAuthorizationRequestModal.vue'
import { EFTRequestStatus } from '@/models/Partner/EFTRequestStatus'
import partnersClient from '@/clients/partnersClient'
import * as upwardRunningStore from '@/store/upwardRunning'
import { Action, Getter } from 'vuex-class'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import SetupCompleteConfirmationUR from '@/components/UpwardRunning/SetupCompleteConfirmationUR.vue'
import ManageDiscountCodesUR from '@/components/UpwardRunning/ManageDiscountCodesUR.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import SaveContinueButtonUR from '@/elements/UpwardRunning/SaveContinueButtonUR.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import store from '@/store'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    Loading,
    TextInput,
    DateTimeInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    InputLabel,
    CurrencyInput,
    SetupCompleteConfirmationUR,
    ManageDiscountCodesUR,
    IntegerInput,
    HorizontalTabs,
    SaveContinueButtonUR,
    HtmlEditor,
    EFTAuthorizationRequestModal,
    Modal,
  },
})
export default class Registration extends Mixins(UpwardRunningSetupMixin) {
  @Action(upwardRunningStore.actionNames.create, { namespace: upwardRunningStore.namespace })
  private readonly create!: ({ item }: { item: PartnerChallenge }) => Promise<PartnerChallenge | null>

  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private accountNumber!: string

  private modalIsVisible = false
  private newId = ''
  private eftBankAcctLast4 = ''
  private registrationLoaded = false
  private eftModalIsVisible = false
  private eftStatus: EFTRequestStatus | null = null
  private showCreditCardHelp = false
  private allowDiscountCodes = false

  async mounted() {
    this.afterSaveNew = this.afterSaveNewMethod

    if (this.challenge.upwardChallengeID) {
      this.nextRoute = '/'
    }

    this.allowDiscountCodes = this.challenge.discountCodes.length > 0

    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)
  }

  get showAccessCode(): boolean {
    return this.eftStatus?.status != 'declined' && this.eftStatus?.status != 'voided' && this.accessCode != ''
  }

  get accessCode(): string {
    return this.eftStatus?.accessCode ?? ''
  }

  get envStatus(): string {
    return this.eftStatus?.status ?? ''
  }

  get partner() {
    return store.getters.partners.partnerInfo
  }

  get ccEnabled() {
    //for some reason this doesn't work
    //return this.eftBankAcctLast4 && this.eftBankAcctLast4 != ''
    if (this.eftBankAcctLast4 && this.eftBankAcctLast4 != '') {
      return true
    } else {
      return false
    }
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.accountNumber) {
      this.eftBankAcctLast4 = this.partner?.eftBankAcctLast4 ?? ''
    }

    this.registrationLoaded = true
  }

  private async afterSaveNewMethod() {
    const savedChallenge = await this.create({ item: this.challenge })
    if (savedChallenge) {
      this.newId = savedChallenge.upwardChallengeID!
    }

    this.modalIsVisible = true
  }

  private onModalStateChange() {
    this.$router.push(`/programs/running/${this.newId}/setup/information`)
  }

  private async eftAuthSent() {
    this.eftModalIsVisible = false
    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)
  }
}
