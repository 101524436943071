



































import { defineComponent, onMounted, computed, ref } from '@vue/composition-api'
import ResumeProgramItem from '@/components/ResumeProgramItem.vue'
import ResumeURProgram from '@/components/UpwardRunning/ResumeURProgram.vue'
import sessionObjectsClient from '@/clients/sessionObjectsClient'
import store from '@/store'
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import { Clinic } from '@/GeneratedTypes/Clinic'
import { Connect } from '@/GeneratedTypes/Connect'
import {
  LeagueAbstraction,
  leagueToAbstraction,
  campToAbstraction,
  clinicToAbstraction,
  connectToAbstraction,
} from '@/models/LeagueAbstraction/LeagueAbstraction'
import { getEmptyStartingSelection, StartingSelection } from '@/models/League/StartingSelection'
import { LeagueTypeEnum } from '@/models/LeagueAbstraction/enums/LeagueTypeEnum'
import { UpwardRunningCache } from '@/store/upwardRunning'

export default defineComponent({
  name: 'ResumeProgram',
  props: {},
  components: { ResumeProgramItem, ResumeURProgram },
  setup(props, { root, emit }) {
    const userData = computed(() => store.getters.authorization.userData)
    const firstAccount = computed(() => store.getters.authorization.firstAccountNumber)
    const programs = ref<LeagueAbstraction[]>([])
    const league = ref<League | null>(null)
    const clinic = ref<Clinic | null>(null)
    const connect = ref<Connect | null>(null)
    const camp = ref<Camp | null>(null)
    const runningCache = ref<UpwardRunningCache | null>(null)
    const show = computed(() => {
      emit('partialCount', programs.value.length ?? 0 + (runningCache.value ? 1 : 0))
      return programs.value.length > 0 || runningCache.value
    })

    onMounted(async () => {
      const r = await Promise.allSettled([
        cachedLeague(),
        cachedCamp(),
        cachedClinic(),
        cachedConnect(),
        getCachedRunning(),
      ])
      if (r[0].status == 'fulfilled' && r[0].value) programs.value.push(r[0].value)
      if (r[1].status == 'fulfilled' && r[1].value) programs.value.push(r[1].value)
      if (r[2].status == 'fulfilled' && r[2].value) programs.value.push(r[2]?.value)
      if (r[3].status == 'fulfilled' && r[3].value) programs.value.push(r[3]?.value)
      programs.value = programs.value.filter((p) => p.leagueName != 'ESTIMATE')
      // await getCachedRunning()
    })

    const uniqueId = computed(() => {
      return `${firstAccount.value}.${userData.value.replace(/[^a-zA-Z0-9 ]/g, '')}`
    })

    async function cachedLeague() {
      const r = await sessionObjectsClient.retrieveData<League>(uniqueKey(LeagueTypeEnum.LEAGUE))
      if (r) {
        league.value = r
        return leagueToAbstraction(r)
      }
      return null
    }

    async function cachedCamp() {
      const r = await sessionObjectsClient.retrieveData<Camp>(uniqueKey(LeagueTypeEnum.CAMP))
      if (r) {
        camp.value = r
        return campToAbstraction(r)
      }
      return null
    }

    async function cachedConnect() {
      const r = await sessionObjectsClient.retrieveData<Connect>(uniqueKey(LeagueTypeEnum.CONNECT))
      if (r) {
        connect.value = r
        return connectToAbstraction(r)
      }
      return null
    }

    async function cachedClinic() {
      const r = await sessionObjectsClient.retrieveData<Clinic>(uniqueKey(LeagueTypeEnum.CLINIC))
      if (r) {
        clinic.value = r
        return clinicToAbstraction(r)
      }
      return null
    }

    async function getCachedRunning() {
      const r = await sessionObjectsClient.retrieveData<UpwardRunningCache>(uniqueKey(LeagueTypeEnum.RUNNING))
      if (r) {
        runningCache.value = r
      }
    }

    function uniqueKey(type: LeagueTypeEnum) {
      switch (type) {
        case LeagueTypeEnum.CAMP:
          return `camp.new.${uniqueId.value}`
        case LeagueTypeEnum.LEAGUE:
          return `league.new.${uniqueId.value}`
        case LeagueTypeEnum.CLINIC:
          return `clinic.new.${uniqueId.value}`
        case LeagueTypeEnum.CONNECT:
          return `connect.new.${uniqueId.value}`
        case LeagueTypeEnum.RUNNING:
          return `running.new.${uniqueId.value}`
        default:
          return ''
      }
    }

    async function remove(type: LeagueTypeEnum) {
      await sessionObjectsClient.delete(uniqueKey(type))
      programs.value = programs.value.filter((p) => p.type != type)
    }

    async function removeRunning() {
      await sessionObjectsClient.delete(uniqueKey(LeagueTypeEnum.RUNNING))
      runningCache.value = null
    }

    function clicked(type: LeagueTypeEnum) {
      if (type == LeagueTypeEnum.LEAGUE) {
        store.commit.leagues.setCurrent({ item: league.value as League })
        store.commit.leagues.setStartingSelection({ selection: selection(league.value) })
        root.$router.push('/programs/league/setup/information')
      }
      if (type == LeagueTypeEnum.CAMP) {
        store.commit.camps.setCurrent({ item: camp.value as Camp })
        root.$router.push('/programs/camp/setup/information')
      }
      if (type == LeagueTypeEnum.CLINIC) {
        store.commit.camps.setCurrent({ item: clinic.value as Clinic })
        root.$router.push('/programs/clinic/setup/information')
      }
      if (type == LeagueTypeEnum.CONNECT) {
        store.commit.connects.setCurrent({ item: connect.value as Connect })
        root.$router.push('/programs/connect/setup/information')
      }
    }

    function clickedRunning() {
      if (runningCache.value) {
        store.commit.upwardRunning.setCurrentChallenge({ challenge: runningCache.value.challenge })
        store.commit.upwardRunning.setCurrentPartnerContact({ contact: runningCache.value?.contact })
        root.$router.push('/programs/running/setup/information')
      }
    }

    function selection(l: League | null): StartingSelection {
      if (!l) throw new Error('Cannot Resume Program. Missing League.')
      return {
        ...getEmptyStartingSelection(),
        upwardTypeId: l.typeLeagueID,
        name: l.leagueName,
      }
    }

    return { programs, runningCache, show, remove, clicked, removeRunning, clickedRunning }
  },
})
