import { GetterTree, Commit } from 'vuex'
import { RootState } from '@/store/rootState'
import participantClient from '@/clients/participantsClient'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { PlayerId } from '@/lib/support/models/LeaguePlayer/data'
import { leaguePlayerToLeaguePlayerInfo } from '@/lib/support/models/LeaguePlayerInfo/LeaguePlayerToLeaguePlayerInfo'
import { defineActions, defineMutations } from 'direct-vuex'
import { moduleActionContext } from '@/store/index'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { cloneDeep } from 'lodash'
import {
  DataTablePagination,
  DataTableRemovedPagination,
  getEmptyDataTablePagination,
  getEmptyDataTableRemovedPagination,
} from '@/models/DataTable/DataTableSelection'

/***
 * Builds the store for participant.
 * LeaguePlayerInfo is the query model
 * LeaguePlayer is the commit model
 *
 */

export const NEW_PARTICIPANT = -1
export const NO_ACTIVE_PARTICIPANT = -2

class ParticipantException extends Error {
  public innerException: Error | undefined = undefined
  constructor(message: string, exception?: Error) {
    super(message)
    this.message = message
    this.name = 'ParticipantException'
    this.innerException = exception
  }
}
class ParticipantRuntimeException extends Error {
  public innerException: Error | undefined = undefined
  constructor(message: string, exception?: Error) {
    super(message)
    this.message = message
    this.name = 'Participant Runtime Exception'
    this.innerException = exception
  }
}

function throwError(message: string, commit?: Commit, error?: Error) {
  commit && commit(mutationNames.setError, { message, error })
  throw new ParticipantException(message, error)
}

enum RetrieveEnum {
  nothing,
  clone,
  new,
  edit,
}

interface ParticipantState {
  currentItem?: LeaguePlayerInfo
  currentRemovedPartcipant?: LeaguePlayerInfo
  participants: LeaguePlayerInfo[]
  removedParticipants: LeaguePlayerInfo[]
  lastLeagueLoaded: string
  lastError: string
  lastException: Error | null
  participantUnderEdit: LeaguePlayer | null
  participantUnderEditId: PlayerInfoIDType
  currentProgram: string //valid program
  operation: RetrieveEnum
  loading: boolean
  //** differentiates list and player loading.
  playerLoading: boolean
  status: string
  skipParticipantListRefresh: boolean
  pagination: DataTablePagination
  removedPagination: DataTableRemovedPagination
}

const initState = (): ParticipantState => {
  return {
    currentItem: undefined,
    currentRemovedPartcipant: undefined,
    participants: [],
    removedParticipants: [],
    lastLeagueLoaded: '',
    lastError: '',
    lastException: null,
    participantUnderEdit: null, //initial state null.
    participantUnderEditId: NO_ACTIVE_PARTICIPANT,
    currentProgram: '',
    operation: RetrieveEnum.nothing,
    loading: false,
    playerLoading: false,
    status: '',
    skipParticipantListRefresh: false, //used for cancelled operations between routes
    pagination: getEmptyDataTablePagination(),
    removedPagination: getEmptyDataTableRemovedPagination(),
  }
}
const participantState: ParticipantState = initState()

export interface LoadProgramParticipantsArgs {
  leagueId: string
  typeProgramId?: string
  shouldForceRefresh?: boolean
}

export interface SingleParticipantArgs {
  participant: LeaguePlayerInfo
}

export interface SingleEditParticipantArgs {
  participant: LeaguePlayer
}

export enum getterNames {
  currentItem = 'currentItem',
  currentRemovedPartcipant = 'currentRemovedParticipant',
  participants = 'participants',
  removedParticipants = 'removedParticipants',
  participantUnderEdit = 'participantUnderEdit',
  currentProgram = 'currentProgram',
  loading = 'loading',
  status = 'status',
  skipParticipantListRefresh = 'skipParticipantListRefresh',
  pagination = 'pagination',
  removedPagination = 'removedPagination',
}

const getterTree: GetterTree<ParticipantState, RootState> = {
  currentItem: (state) => state.currentItem,
  currentRemovedParticipant: (state) => state.currentRemovedPartcipant,
  participants: (state) => state.participants,
  removedParticipants: (state) => state.removedParticipants,
  participantUnderEdit: (state) => state.participantUnderEdit,
  currentProgram: (state) => state.currentProgram,
  loading: (state) => state.loading,
  playerLoading: (state) => state.playerLoading,
  status: (state) => state.status,
  skipParticipantListRefresh: (state) => state.skipParticipantListRefresh,
  pagination: (state) => state.pagination,
  removedPagination: (state) => state.removedPagination,
}

export enum mutationNames {
  setCurrent = 'setCurrent',
  setCurrentRemovedParticipant = 'setCurrentRemovedParticipant',
  clearCurrent = 'clearCurrent',
  clearCurrenRemovedParticipant = 'clearCurrentRemovedParticipant',
  setParticipants = 'setParticipants',
  setRemovedParticipants = 'setRemovedParticipants',
  setError = 'setError',
  setLastLeagueLoaded = 'setLastLeagueLoaded',
  setParticipantUnderEdit = 'setParticipantUnderEdit',
  setParticipantUnderEditId = 'setParticipantUnderEditId',
  setCurrentProgram = 'setCurrentProgram',
  setUpdatedPlayer = 'setUpdatedPlayer',
  setRetrieveType = 'setRetrieveType',
  removeParticipant = 'removeParticipant',
  updateParticipantData = 'updateParticipantData',
  setLoading = 'setLoading',
  setPlayerLoading = 'setPlayerLoading',
  setStatus = 'setStatus',
  clearParticipant = 'clearParticipant',
  setSkipParticipantListRefresh = 'setSkipParticipantListRefresh',
  setPagination = 'setPagination',
  setRemovedPaginaton = 'setRemovedPagination',
}

const mutations = defineMutations<ParticipantState>()({
  setStatus(state, status: string) {
    state.status = status
  },
  setLoading(state, loading: boolean) {
    state.loading = loading
  },

  setPlayerLoading(state, loading: boolean) {
    state.playerLoading = loading
  },

  setRetrieveType(state, { operation }: { operation: RetrieveEnum }) {
    state.operation = operation
  },
  setCurrent(state, { participant }: { participant: LeaguePlayerInfo }) {
    if (participant === undefined) {
      throw new ParticipantRuntimeException('participant undefined, check arguments.')
    }
    state.currentItem = participant
  },
  setCurrentRemovedParticipant(state, { participant }: { participant: LeaguePlayerInfo }) {
    if (participant === undefined) {
      throw new ParticipantRuntimeException('participant undefined, check arguments.')
    }
    state.currentRemovedPartcipant = participant
  },
  clearCurrent(state) {
    state.currentItem = undefined
  },
  clearCurrentRemovedParticipant(state) {
    state.currentRemovedPartcipant = undefined
  },
  setUpdatedPlayer(state, { participant }: SingleParticipantArgs) {
    if (participant === undefined) {
      throw new ParticipantRuntimeException('participant undefined, check arguments.')
    }
    const idx = state.participants.findIndex((x) => x.individualID == participant.individualID)
    if (idx >= 0) {
      state.participants.splice(idx, 1, participant)
    } else {
      state.participants.push(participant)
    }
  },

  removeParticipant(state, { id }: { id: PlayerInfoIDType }) {
    if (state.participantUnderEditId == id) {
      state.participantUnderEditId = NO_ACTIVE_PARTICIPANT
      state.participantUnderEdit = null
    }
    state.participants = [...state.participants.filter((x) => x.individualID != id)]
  },

  updateParticipantData(state: ParticipantState, { playerInfo }: { playerInfo: LeaguePlayerInfo }) {
    const participantIndex = state.participants.findIndex((x) => x.individualID == playerInfo.individualID)

    state.participants[participantIndex] = playerInfo
    state.participants = [...state.participants]
  },

  setParticipants(state: ParticipantState, { items }: { items: LeaguePlayerInfo[] }) {
    if (items === undefined) {
      throw new ParticipantRuntimeException('items undefined, check arguments.')
    }
    state.participants = items
  },
  setRemovedParticipants(state: ParticipantState, { items }: { items: LeaguePlayerInfo[] }) {
    if (items === undefined) {
      throw new ParticipantRuntimeException('items undefined, check arguments.')
    }
    state.removedParticipants = items
  },
  setError(state, { error, exception }: { error: string; exception?: Error }) {
    if (error === undefined) {
      throw new ParticipantRuntimeException('Error undefined, check arguments.')
    }
    //@todo - propagate to general error -
    state.lastError = error
    state.lastException = exception || null
    console.warn('participant store', error, exception)
  },
  setLastLeagueLoaded(state, { leagueID }: { leagueID: string }) {
    if (leagueID == undefined) {
      throw new ParticipantRuntimeException('Error undefined, check arguments.')
    }
    state.lastLeagueLoaded = leagueID
  },
  setParticipantUnderEdit(state, { participant }: SingleEditParticipantArgs) {
    if (participant === undefined) {
      throw new ParticipantRuntimeException('Error undefined, check arguments.')
    }
    state.participantUnderEdit = { ...participant }
  },
  setParticipantUnderEditId(state, { id }: { id: PlayerInfoIDType }) {
    state.participantUnderEditId = id
  },
  setCurrentProgram(state, { program }: { program: string }) {
    state.currentProgram = program
  },
  setSkipParticipantListRefresh(state, { flag }: { flag: boolean }) {
    // used to flag cancel operations beteween routes to circumvent unnecessary particpant list refreshes
    state.skipParticipantListRefresh = flag
  },
  [mutationNames.clearParticipant](state) {
    state.participantUnderEdit = null
    state.participantUnderEditId = NO_ACTIVE_PARTICIPANT
  },
  setPagination(state, { pagination }: { pagination: DataTablePagination }) {
    state.pagination = pagination
  },
  setRemovedPagination(state, { removedPagination }: { removedPagination: DataTableRemovedPagination }) {
    state.removedPagination = removedPagination
  },
})

/**
 * Templates sometimes have fake blank contacts. This will remove those
 * @param contacts
 * @return contacts that have IDs, names etc.
 */
function realContactFromFakers(contacts: PlayerContact[]) {
  const c: PlayerContact[] = []
  contacts.forEach((x) => {
    if (x.individualID) {
      c.push(cloneDeep(x))
    }
  })
  return c
}

export enum actionNames {
  removeParticipant = 'removeParticipant',
  unRemoveParticipant = 'unRemoveParticipant',
  loadProgramParticipants = 'loadProgramParticipants',
  loadRemovedParticipants = 'loadRemovedParticipants',
  setCurrentParticipantByID = 'setCurrentParticipantByID',
  setCurrentRemovedParticipantByID = 'setCurrentRemovedParticipantByID',
  getNewTemplate = 'getNewTemplate',
  upsertParticipant = 'upsertParticipant',
  getParticipant = 'getParticipant',
  setAccountOnParticipant = 'setAccountOnParticipant',
}

const actions = defineActions({
  async setAccountOnParticipant(
    context,
    { leagueID, ids, newAccount }: { leagueID: string; ids: number[]; newAccount: string }
  ) {
    const { commit } = pactionContext(context)
    let q = 0
    for (let i1 = 0; i1 < ids.length; i1++) {
      const i = ids[i1]
      q++
      commit.setStatus(`Saving ${((q * 100) / ids.length).toFixed(1)}% `)
      const p = await participantClient.retrieveParticipant(leagueID, i)
      if (p) {
        p.accountNumber = newAccount
        try {
          await participantClient.save(leagueID, p)
          commit.setUpdatedPlayer({ participant: leaguePlayerToLeaguePlayerInfo(p) })
        } catch (e) {
          commit.setStatus(e.getMessage())
        }
      }
    }
    commit.setStatus(`done`)
  },

  async upsertParticipant(
    ctx,
    { leagueID, participant, matched }: { leagueID: string; participant: LeaguePlayer; matched: boolean }
  ) {
    const { commit } = pactionContext(ctx)
    commit.setPlayerLoading(true)
    try {
      await participantClient.validate(leagueID, participant, matched)
      const newplayer = await participantClient.save(leagueID, participant, matched)
      if (newplayer) {
        commit.setUpdatedPlayer({ participant: leaguePlayerToLeaguePlayerInfo(newplayer) })
      }
    } finally {
      commit.setPlayerLoading(false)
    }

    commit.setParticipantUnderEditId({ id: NO_ACTIVE_PARTICIPANT })
  },

  /***
   * Loads participants, leagueid and program type are found in the state machine
   * @param commit
   * @param state
   * @param leagueid
   * @param leagueId
   * @param typeProgramId
   * @param shouldForceRefresh
   */
  async loadProgramParticipants(
    { commit, state },
    { leagueId, typeProgramId, shouldForceRefresh }: LoadProgramParticipantsArgs
  ) {
    try {
      if (state.lastLeagueLoaded != leagueId || shouldForceRefresh) {
        commit('setLoading', true)
        const result = await participantClient.retrieve(leagueId, typeProgramId)

        commit(mutationNames.setLastLeagueLoaded, { leagueID: leagueId })
        commit(mutationNames.setParticipants, { items: result })
      }
    } catch (ex) {
      throwError('Error with the retrieval of participants', commit, ex)
    } finally {
      commit('setLoading', false)
    }
  },

  async loadRemovedParticipants(
    { commit, state },
    { leagueId, typeProgramId, shouldForceRefresh }: LoadProgramParticipantsArgs
  ) {
    try {
      if (state.lastLeagueLoaded != leagueId || shouldForceRefresh) {
        commit('setLoading', true)
        const result = await participantClient.retrieveRemoved(leagueId, typeProgramId)

        commit(mutationNames.setLastLeagueLoaded, { leagueID: leagueId })
        commit(mutationNames.setRemovedParticipants, { items: result })
      }
    } catch (ex) {
      throwError('Error with the retrieval of removed participants', commit, ex)
    } finally {
      commit('setLoading', false)
    }
  },

  /***
   * Sets a current application state for a single selected participant.
   * @param commit
   * @param context
   * @param id
   */
  async setCurrentParticipantByID(
    context,
    { id, typeProgramID }: { id: PlayerInfoIDType; typeProgramID: string }
  ) {
    const { state, dispatch, commit } = pactionContext(context)
    try {
      if (!state.participants) {
        throwError("Can't find an ID on an empty list of participants", context.commit)
        return
      }
      // do we already have it as current item?
      if (
        state.participantUnderEditId == id &&
        state.currentItem &&
        PlayerId(state.currentItem) == id &&
        state.currentItem.typeProgramID == typeProgramID
      ) {
        return
      }

      // go ahead and preload the template, the vue components will shuffle it in when it
      // gets there, preferring the league player we already have in memory as a place to start.

      const index = state.participants.findIndex((y) => PlayerId(y) == id && y.typeProgramID == typeProgramID)

      if (index >= 0) {
        commit.setCurrent({ participant: state.participants[index] })
        await dispatch.getParticipant({ id: PlayerId(state.participants[index]) })
        return
      }
    } catch (ex) {
      commit.clearCurrent()
      throwError(ex.message, context.commit, ex)
    }
    throwError(`Participant not current in edit ${id}`, context.commit)
  },
  setCurrentRemovedParticipantByID(
    context,
    { id, typeProgramID }: { id: PlayerInfoIDType; typeProgramID: string }
  ) {
    const { state, commit } = pactionContext(context)
    try {
      if (!state.removedParticipants) {
        throwError("Can't find an ID on an empty list of participants", context.commit)
        return
      }
      // do we already have it as current item?
      if (
        state.currentRemovedPartcipant &&
        PlayerId(state.currentRemovedPartcipant) == id &&
        state.currentRemovedPartcipant.typeProgramID == typeProgramID
      ) {
        return
      }

      // go ahead and preload the template, the vue components will shuffle it in when it
      // gets there, preferring the league player we already have in memory as a place to start.

      const index = state.removedParticipants.findIndex(
        (y) => PlayerId(y) == id && y.typeProgramID == typeProgramID
      )

      if (index >= 0) {
        commit.setCurrentRemovedParticipant({ participant: state.removedParticipants[index] })
        // await dispatch.getParticipant({ id: PlayerId(state.removedParticipants[index]) })
        return
      }
    } catch (ex) {
      commit.clearCurrent()
      throwError(ex.message, context.commit, ex)
    }
    throwError(`Participant not current in edit ${id}`, context.commit)
  },
  async removeParticipant(
    { commit },
    { upwardLeagueID, id }: { upwardLeagueID: UpwardLeagueIDType; id: PlayerInfoIDType }
  ) {
    commit('setLoading', true)
    try {
      await participantClient.remove(upwardLeagueID, id)
      commit(mutationNames.removeParticipant, { id })
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async unRemoveParticipant(
    { commit },
    {
      upwardLeagueID,
      id,
      typeProgramID,
    }: { upwardLeagueID: UpwardLeagueIDType; id: PlayerInfoIDType; typeProgramID: string }
  ) {
    commit('setLoading', true)
    try {
      await participantClient.unRemove(upwardLeagueID, id, typeProgramID)
      commit(mutationNames.clearCurrenRemovedParticipant)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  /***
   * Grab the specified participant (won't use the selected due to new and copy UC's)
   * The optional league ID is used for the import scenario where contacts need to be copied from another league.
   *
   */
  async getNewTemplate(
    ctx,
    { id, importLeagueID }: { id?: PlayerInfoIDType; importLeagueID?: string }
  ): Promise<void> {
    let template = null
    const { commit, state } = pactionContext(ctx)
    try {
      if (state.participantUnderEditId == id && state.operation != RetrieveEnum.edit) {
        return //nothing to communicate.
      }
      commit.setParticipantUnderEditId({ id: id ?? NEW_PARTICIPANT })
      commit.setPlayerLoading(true)
      template = await participantClient.retrieveTemplate(state.lastLeagueLoaded, id, importLeagueID)

      if (template) {
        template = { ...template, contacts: realContactFromFakers(template?.contacts ?? []) } // not useful to give us a fake blank contact.
        commit.setParticipantUnderEdit({ participant: template as LeaguePlayer })
      }
    } catch (ex) {
      throwError(ex.message, ctx.commit, ex)
    } finally {
      commit.setPlayerLoading(false)
    }
    if (!template) {
      //load failed.
      commit.setRetrieveType({ operation: RetrieveEnum.nothing })
      commit.setParticipantUnderEditId({ id: NO_ACTIVE_PARTICIPANT })
      throwError(`Server returned an invalid template for ${id}`)
      return
    }
  },
  /***
   * Grab the specified participant as an edit (won't use the selected due to new and copy UC's)
   */
  async getParticipant(ctx, { id }: { id: PlayerInfoIDType }): Promise<void> {
    let template = null
    const { commit, state } = pactionContext(ctx)

    try {
      if (state.participantUnderEditId == id && state.operation == RetrieveEnum.edit) {
        return //nothing to communicate.
      }

      commit.setParticipantUnderEditId({ id })

      commit.setPlayerLoading(true)
      template = await participantClient.retrieveParticipant(state.lastLeagueLoaded, id)
    } catch (ex) {
      throwError(ex.message, ctx.commit, ex)
    } finally {
      commit.setPlayerLoading(false)
    }

    if (template) {
      commit.setParticipantUnderEdit({ participant: template })
    } else {
      //load failed.
      commit.setRetrieveType({ operation: RetrieveEnum.nothing })

      commit.setParticipantUnderEditId({ id: NO_ACTIVE_PARTICIPANT })
      throwError(`Server returned an invalid template for ${id}`)
      return
    }
  },
})

export const namespace = 'participants'

export const participants = {
  namespaced: true as true,
  state: participantState as ParticipantState,
  getters: getterTree,
  mutations,
  actions,
}
const pactionContext = (context: any) => moduleActionContext(context, participants)
