import { RouteConfig } from 'vue-router'
import { urIDExpression } from '@/lib/support/router/idHelper'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

import Setup from '@/views/UpwardRunning/Setup.vue'
import UpwardRunningIndex from '@/views/UpwardRunning/UpwardRunningIndex.vue'
import Information from '@/views/UpwardRunning/Information.vue'
import KeyDates from '@/views/UpwardRunning/KeyDates.vue'
import Leadership from '@/views/UpwardRunning/Leadership.vue'
import Products from '@/views/UpwardRunning/Products.vue'
import Registration from '@/views/UpwardRunning/Registration.vue'
import Archive from '@/views/UpwardRunning/Archive.vue'

const ParticipantsIndex = () => import('@/views/UpwardRunning/Participants/ParticipantsIndex.vue')
const ParticipantsView = () => import('@/views/UpwardRunning/Participants/ParticipantsView.vue')

// const Communications = () => import('@/views/UpwardRunning/Communications/Communications.vue')
// const History = () => import('@/views/Programs/Communications/History.vue')
// const Settings = () => import('@/views/Programs/Communications/Settings.vue')
// const Email = () => import('@/views/UpwardRunning/Communications/Email.vue')
// const Sms = () => import('@/views/UpwardRunning/Communications/Sms.vue')
// const Scheduled = () => import('@/views/Programs/Communications/Scheduled.vue')
// const Failures = () => import('@/views/Programs/Communications/Failures.vue')

const OrdersIndex = () => import('@/views/UpwardRunning/Orders/OrdersIndex.vue')
const ShippingTimelines = () => import('@/views/UpwardRunning/Orders/ShippingTimelines.vue')
const OrderHistory = () => import('@/views/UpwardRunning/Orders/OrderHistory.vue')
const OrderRunning = () => import('@/views/UpwardRunning/Orders/OrderRunning.vue')
// const Returns = () => import('@/views/UpwardRunning/Orders/Returns.vue')
const OrderFrame = () => import('@/views/UpwardRunning/Orders/OrderFrame.vue')
const OrderDetailPage = () => import('@/views/UpwardRunning/Orders/OrderDetailPage.vue')

// import Reports from '@/views/UpwardRunning/Reports/Index.vue'
// import ExcelReports from '@/views/UpwardRunning/Reports/ExcelReports.vue'
// import WebviewReports from '@/views/UpwardRunning/Reports/WebviewReports.vue'
// import DynamicReports from '@/views/UpwardRunning/Reports/DynamicReports.vue'

import Resources from '@/views/UpwardRunning/Resources/Resources.vue'

export default [
  {
    path: `running/${urIDExpression}`,
    component: UpwardRunningIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/running/${urIDExpression}/setup`,
      },
      //participants
      {
        path: 'participants',
        component: ParticipantsIndex,
        children: [
          {
            path: '/',
            redirect: `/programs/running/${urIDExpression}/participants/list`,
          },
          {
            path: 'list',
            component: ParticipantsView,
            name: `list-participant-running`,
          },
          {
            path: 'search',
            component: ParticipantsView,
            name: `search-participant-running`,
          },
        ],
      },
      //communications
      // {
      //   path: 'communications',
      //   component: Communications,
      //   children: [
      //     {
      //       path: '/',
      //       redirect: `/programs/running/${urIDExpression}/communications/email`,
      //     },
      //     {
      //       path: 'email/:messageId?',
      //       component: Email,
      //     },
      //     {
      //       path: 'sms/:messageId?',
      //       component: Sms,
      //     },
      //     {
      //       path: 'failures',
      //       component: Failures,
      //     },
      //     { path: 'scheduled', component: Scheduled },
      //     {
      //       path: 'history',
      //       component: History,
      //     },
      //     { path: 'settings', component: Settings },
      //   ],
      // },
      //orders
      {
        path: 'ordering',
        component: OrdersIndex,
        children: [
          {
            path: 'running/:step',
            component: OrderRunning,
          },
          // {
          //   path: 'running',
          //   redirect: `/programs/running/${urIDExpression}/ordering/running/0`,
          // },
          // {
          //   path: 'returns',
          //   component: Returns,
          // },
          {
            path: 'shippingtimelines',
            component: ShippingTimelines,
          },
          {
            path: 'history',
            component: OrderHistory,
          },
          {
            path: 'history/order-detail/:orderid',
            component: OrderDetailPage,
          },
          {
            path: `products/${OrderTypesEnum.runningStartup}/:step`,
            component: OrderFrame,
          },
          {
            path: 'products/',
            redirect: `/programs/running/${urIDExpression}/ordering/products/${OrderTypesEnum.runningStartup}/select`,
          },
          {
            path: '/',
            redirect: `/programs/running/${urIDExpression}/ordering/shippingtimelines`,
          },
        ],
      },
      //resources
      {
        name: `RUnningResources`,
        path: 'resources',
        component: Resources,
      },
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/running/${urIDExpression}/setup/information`,
          },
          {
            path: 'information',
            component: Information,
          },
          {
            path: 'dates',
            component: KeyDates,
          },
          {
            path: 'leadership',
            component: Leadership,
          },
          {
            path: 'products',
            component: Products,
          },
          {
            path: 'registration',
            component: Registration,
          },
          {
            path: 'archive',
            component: Archive,
          },
        ],
      },
      //reports
      // {
      //   path: 'reports',
      //   component: Reports,
      //   children: [
      //     {
      //       path: 'excel/:reportName',
      //       component: ExcelReports,
      //     },
      //     {
      //       path: 'web/:reportName',
      //       component: WebviewReports,
      //     },
      //     {
      //       path: 'dynamic/:reportName',
      //       component: DynamicReports,
      //     },
      //   ],
      // },
    ],
  },
] as RouteConfig[]
