



















import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import NumberInput from '@/elements/NumberInput.vue'
import EnlargeableProduct from '@/components/ProductFlow/ParticipantKitStepper/ProductPickers/EnlargeableProduct.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import { getEmptyStartupOrderProductInfo } from '@/lib/support/models/StartupOrderProductInfo'
import {
  PartnerChallengeStartupProductConfig,
  getEmptyPartnerChallengeStartupProductConfig,
} from '@/models/UpwardRunning/PartnerChallengeStartupProductConfig'
import store from '@/store'

export default defineComponent({
  name: 'StartupOrderProductCardUR',
  components: {
    NumberInput,
    EnlargeableProduct,
    SelectorCard,
  },
  props: {
    product: {
      type: Object as PropType<StartupOrderProductInfo>,
      default: getEmptyStartupOrderProductInfo,
      required: false,
    },
    value: {
      type: Object as PropType<PartnerChallengeStartupProductConfig>,
      default: null,
      required: false,
    },
  },
  setup(props, ctx) {
    const startupConfig = computed(() => store.getters.upwardRunning.currentChallenge.startupProductConfig)
    const quantity = ref<number | null>(0)

    onMounted(() => {
      setSaved()
    })

    watch(
      () => startupConfig.value,
      () => {
        setSaved()
      }
    )

    function setSaved() {
      if (!startupConfig.value) return
      const savedProduct = startupConfig.value.find(
        (x: PartnerChallengeStartupProductConfig) => x.upwardProductID === props.product.upwardProductID
      )
      quantity.value = savedProduct ? savedProduct.quantity : null
    }

    function buildStartupOrderProduct(): PartnerChallengeStartupProductConfig | null {
      if (!quantity.value || quantity.value < 1) return null
      const prod = getEmptyPartnerChallengeStartupProductConfig()
      prod.upwardProductID = props.product.upwardProductID
      prod.quantity = quantity.value
      return prod
    }

    watch(
      () => quantity.value,
      () => {
        ctx.emit('input', buildStartupOrderProduct())
      }
    )

    return {
      quantity,
    }
  },
})
