





import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import FacilityList from './FacilityList.vue'
import FacilityEditor from './FacilityEditor.vue'
import * as facilityStore from '@/store/facilities'
import store from '@/store'

export default defineComponent({
  name: 'Facilities',
  components: {
    FacilityList,
    FacilityEditor,
  },
  setup() {
    const viewMode = computed(() => store.getters.facilities.viewMode)

    const componentName = ref('FacilityList')

    onMounted(() => {
      store.dispatch.facilities.endEdit(null)
    })

    watch(
      () => viewMode.value,
      () => {
        if (isInEditOrCreateMode()) {
          componentName.value = 'FacilityEditor'
        } else {
          componentName.value = 'FacilityList'
        }
      },
      { immediate: true }
    )

    function isInEditOrCreateMode(): boolean {
      return (
        viewMode.value === facilityStore.viewModes.edit || viewMode.value === facilityStore.viewModes.create
      )
    }

    return {
      componentName,
    }
  },
})
