










































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import LeagueOverview from '@/components/LeagueOverview.vue'
import ClinicOverview from '@/components/ClinicOverview.vue'
import ConnectOverview from '@/components/ConnectOverview.vue'
import CampOverview from '@/components/CampOverview.vue'
import UpwardRunningOverview from '@/components/UpwardRunning/UpwardRunningOverview.vue'
import ResumeProgram from '@/components/ResumeProgram.vue'
import programsClient from '@/clients/programsClient'
import upwardRunningClient from '@/clients/upwardRunningClient'
import Loading from '@/elements/Loading.vue'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { PartnerChallengeInfo } from '@/models/UpwardRunning/PartnerChallengeInfo'
import store from '@/store'
import dayjs, { Dayjs } from 'dayjs'

interface ProgramCardInfo {
  programTypeDescription: string
  leagueID: string
  sortByDate: Dayjs
  isHistorical: boolean
  programInfo: LeagueInfoCondensed | PartnerChallengeInfo
}

export default defineComponent({
  name: 'CurrentPrograms',
  components: {
    ConnectOverview,
    LeagueOverview,
    ClinicOverview,
    CampOverview,
    UpwardRunningOverview,
    Loading,
    ResumeProgram,
  },
  props: {
    resourceOnlyView: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: '' },
    noProgramsText: { type: String, required: false, default: '' },
    includeHistorical: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const programs = ref<ProgramCardInfo[]>([])

    const sortedPrograms = computed(() =>
      programs.value.sort((a, b) => (a.sortByDate == b.sortByDate ? 0 : a.sortByDate > b.sortByDate ? -1 : 1))
    )

    const programsToShow = ref(2)
    const partialProgramCount = ref(0)

    const isLoading = ref(false)

    const resourceLink = computed(() => {
      if (props.resourceOnlyView) {
        return '/resources'
      }
      return ''
    })

    async function fetchAll() {
      if (props.resourceOnlyView) {
        programsToShow.value = 10
      }

      isLoading.value = true
      try {
        const restResult = await programsClient.retrieveAll(props.includeHistorical)

        if (restResult.isSuccess && restResult.data !== null) {
          programs.value = restResult.data.map((x) => {
            return {
              programTypeDescription: x.programTypeDescription ?? '',
              leagueID: x.upwardLeagueID ?? '',
              sortByDate: dayjs(x.firstPracticeDate ?? ''),
              isHistorical: x.isHistorical,
              programInfo: x,
            }
          })
        }
        const urCreds = await store.dispatch.upwardRunningAuth.getURCreds()
        if (urCreds != null) {
          console.log('TNT - getting UR programs)')
          const restResultUR = await upwardRunningClient.retrieveAll(
            store.getters.authorization.firstAccountNumber
          )

          if (restResultUR.isSuccess && restResultUR.data !== null) {
            programs.value.push(
              ...restResultUR.data.map((x) => {
                return {
                  programTypeDescription: 'RUNNING',
                  leagueID: x.upwardChallengeID ?? '',
                  sortByDate: dayjs(x.startDate ?? ''),
                  isHistorical: false, //TODO figure out when UR goes historical
                  programInfo: x,
                }
              })
            )
          }
        } else {
          console.log('TNT - UR Creds not found, skipping UR program fetch)')
        }

        if (props.includeHistorical) {
          programs.value = programs.value.filter((p) => p.isHistorical)
        }
      } finally {
        isLoading.value = false
      }
    }

    const noPrograms = computed(
      () => !isLoading.value && programs.value && programs.value.length == 0 && partialProgramCount.value == 0
    )

    function setPartialCount(count: number) {
      partialProgramCount.value = count
    }

    onMounted(async () => {
      await fetchAll()
    })

    return {
      isLoading,
      noPrograms,
      programsToShow,
      programs,
      resourceLink,
      setPartialCount,
      sortedPrograms,
    }
  },
})
