


























import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { PartnerChallengeDiscountCode } from '@/models/UpwardRunning/PartnerChallengeDiscountCode'
import { SaveIcon } from '@/elements/Icons'

export default defineComponent({
  name: 'EditDiscountCodeUR',
  components: {
    Modal,
    TextInput,
    IntegerInput,
    CurrencyInput,
    CheckboxInput,
    SaveIcon,
  },
  props: {
    discountCode: {
      type: (null as unknown) as PropType<PartnerChallengeDiscountCode | null>,
      required: false,
    },
    challengeID: { type: Number, required: true, default: 0 },
    value: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const internalDiscountCode = ref<PartnerChallengeDiscountCode>({
      challengeID: props.challengeID,
      id: 0,
      description: '',
      discountCode: '',
      discountAmount: 0,
      maxUsages: 0,
      numberOfUsages: 0,
      active: true,
    })

    const internalModalIsVisible = ref(false)
    const modalTitle = ref('Edit Discount Code')

    watch(
      () => internalModalIsVisible.value,
      () => {
        ctx.emit('input', internalModalIsVisible.value)
        ctx.emit('change', internalModalIsVisible.value)
      }
    )

    watch(
      () => internalDiscountCode.value.discountCode,
      () => {
        if (internalDiscountCode.value && internalDiscountCode.value.discountCode) {
          internalDiscountCode.value.discountCode = internalDiscountCode.value.discountCode.toUpperCase()
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (internalModalIsVisible.value !== props.value) {
          internalModalIsVisible.value = props.value
        }
      }
    )

    onMounted(() => {
      internalModalIsVisible.value = props.value
      if (props.discountCode) {
        internalDiscountCode.value = props.discountCode
      }
    })

    function done() {
      if (internalDiscountCode.value && internalDiscountCode.value.discountCode) {
        internalDiscountCode.value.discountCode = internalDiscountCode.value.discountCode.toUpperCase()
      }

      ctx.emit('onSave', internalDiscountCode.value)
      toggleModal()
    }

    function cancel() {
      toggleModal()
    }

    function toggleModal() {
      internalModalIsVisible.value = !internalModalIsVisible.value
    }

    function showModal() {
      internalDiscountCode.value = defaultDiscountCode()
      internalModalIsVisible.value = true
    }

    watch(
      () => props.discountCode,
      () => {
        internalDiscountCode.value = props.discountCode || defaultDiscountCode()
      }
    )

    function defaultDiscountCode(): PartnerChallengeDiscountCode {
      return {
        challengeID: props.challengeID,
        id: 0,
        description: '',
        discountCode: '',
        discountAmount: 0,
        maxUsages: 0,
        numberOfUsages: 0,
        active: true,
      } as PartnerChallengeDiscountCode
    }

    return {
      internalModalIsVisible,
      modalTitle,
      internalDiscountCode,
      cancel,
      done,
      showModal,
    }
  },
})
