import { Contact } from '@/GeneratedTypes/Partner/Contact'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { ContactInfoToContact } from '@/services/contactService'

export interface RootContact extends Contact {
  id: number | null
}

export function getEmptyRootContact(): RootContact {
  return { ...getEmptyContact(), id: 0 }
}

export function ContactInfoToRootContact(contactInfo: ContactInfo): RootContact {
  return { ...ContactInfoToContact(contactInfo), id: contactInfo.id }
}
