


















































interface DisplayableProduct {
  cmsProductName: string | null
  cost: number
  costOffset: number
  typeParticipantID: string | null
  category: string | null
  categorySortOrder: number
  productID: number | null
  productSortOrder: number
  color: string
  optionalText: string
  hasGradeGender: boolean
  skipSimpleTotal: boolean
  isMultiPrice: boolean
  isOptionalApparel: boolean
}

import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import Loading from '@/elements/Loading.vue'
import { PartnerChallengeProductConfig } from '@/models/UpwardRunning/PartnerChallengeProductConfig'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import { PartnerChallengeStartupProductConfig } from '@/models/UpwardRunning/PartnerChallengeStartupProductConfig'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import currency from '@/filters/currency'
import store from '@/store'

export default defineComponent({
  name: 'RunningTotalUR',
  components: {
    Loading,
  },
  props: {
    viewedSteps: { type: Array as PropType<string[]>, required: false, default: () => [] },
  },
  setup(props) {
    const productConfig = computed(() => store.getters.upwardRunning.currentChallenge.productConfig)
    const availableStartupProducts = computed(() => store.getters.productOfferings.availableStartupProducts)
    const startupConfig = computed(() => store.getters.upwardRunning.currentChallenge.startupProductConfig)
    const availableRunningProducts = computed(() => store.getters.upwardRunning.runningOrderProducts)
    const challenge = computed(() => store.getters.upwardRunning.currentChallenge)

    const showOptionalProductText = ref(false)
    const showMultiPriceWarning = ref(false)

    function cost(cost: number, optionalText: string) {
      if (optionalText) return `${currency(cost)}${optionalText}`
      return currency(cost)
    }

    const estimatedNumberOfParticipants = computed(() => challenge.value.participantEstimate ?? 0)

    const startupOrderItems = computed((): DisplayableProduct[] => {
      const item = emptyDisplayableProduct()
      if (hideMe('Startup Order Items')) return []
      const allCosts = totalOfAllStartupOrderItems()
      if (allCosts === 0) return []

      item.cmsProductName = 'Starter Order Items'
      item.cost = allCosts / estimatedNumberOfParticipants.value
      return [item]
    })

    const participantProducts = computed((): DisplayableProduct[] => {
      const products = createDisplayableProductArray()
      return products || []
    })

    const allProducts = computed(() => [...startupOrderItems.value, ...participantProducts.value])

    const participantTotal = computed(() => {
      if (!participantProducts.value) return
      const starupOrderTotal = startupOrderItems.value.reduce((t, p) => t + p.cost, 0)
      const simpleProductTotal = participantProducts.value.reduce(
        (t, p) => (!p.skipSimpleTotal ? t + p.cost : t),
        0
      )
      return Number(simpleProductTotal) + Number(starupOrderTotal)
    })

    function hideMe(step: string) {
      return !props.viewedSteps.includes(step)
    }

    function createDisplayableProductArray(): DisplayableProduct[] {
      let items: DisplayableProduct[] = []
      productConfig.value.forEach((selectedProduct: PartnerChallengeProductConfig) => {
        availableRunningProducts.value.forEach((prodInfo: RunningOrderProductInfo) => {
          if (prodInfo.upwardProductID === selectedProduct.upwardProductID) {
            const item = buildDisplayableProduct(selectedProduct, prodInfo)
            if (!item) return
            items.push(cloneDeep(item))
          }
        })
      })
      return items.sort((a, b) => {
        if (a.categorySortOrder == b.categorySortOrder) {
          return a.productSortOrder > b.productSortOrder ? 1 : -1
        }
        return a.categorySortOrder > b.categorySortOrder ? 1 : -1
      })
    }

    function totalOfAllStartupOrderItems() {
      if (!availableStartupProducts.value) return 0
      return startupConfig.value.reduce((acc: number, y: PartnerChallengeStartupProductConfig) => {
        const product = availableStartupProducts.value.find(
          (p: StartupOrderProductInfo) => p.upwardProductID === y.upwardProductID
        )
        const cost = product && y.quantity ? product.cost * y.quantity : 0
        return acc + cost
      }, 0)
    }

    function modifyForOptionalApparel(item: DisplayableProduct, cost: number) {
      item.cost = cost
      item.cmsProductName = `${item.cmsProductName} (Optional)`
      item.optionalText = `${item.optionalText}*`
      item.skipSimpleTotal = true
    }

    function modifyForMultiPrice(item: DisplayableProduct) {
      item.optionalText = `${item.optionalText}Ɨ`
    }

    function buildDisplayableProduct(
      selectedProduct: PartnerChallengeProductConfig,
      prodInfo: RunningOrderProductInfo
    ) {
      const item = emptyDisplayableProduct()

      item.cmsProductName = prodInfo.cmsProductName
      item.productID = prodInfo.id
      item.productSortOrder = prodInfo.sortOrder
      item.hasGradeGender = false
      item.cost = prodInfo.cost
      item.color = selectedProduct.upwardColorTypeID === 'N/A' ? '' : selectedProduct.upwardColorTypeID ?? ''
      item.isOptionalApparel = false
      item.isMultiPrice = false

      //special treatment for optional items in optional apparel (let parents choose)
      if (item.isOptionalApparel) {
        modifyForOptionalApparel(item, selectedProduct.optionalProductCost)
      }

      //special treatment for products that have multiple possible prices but are not optional (let parents choose)
      if (item.isMultiPrice && !item.isOptionalApparel) {
        modifyForMultiPrice(item)
      }

      return item
    }

    const show = computed(() => {
      if (allProducts.value.length > 0) return true
      return false
    })

    watch(
      () => allProducts.value,
      () => {
        if (!allProducts.value) return
        const modifiedProducts = allProducts.value.filter((p) => p.optionalText != '')
        let hasMultiple = false
        let hasOptional = false

        modifiedProducts.forEach((p) => {
          if (p.isMultiPrice && !p.isOptionalApparel) {
            hasMultiple = true
          }
          if (p.isOptionalApparel) {
            hasOptional = true
          }
        })

        showOptionalProductText.value = hasOptional
        showMultiPriceWarning.value = hasMultiple
      }
    )

    function emptyDisplayableProduct() {
      return {
        cmsProductName: '',
        cost: 0,
        costOffset: 0,
        typeParticipantID: null,
        category: null,
        categorySortOrder: 0,
        productID: null,
        productSortOrder: 0,
        color: '',
        optionalText: '',
        hasGradeGender: false,
        skipSimpleTotal: false,
        isMultiPrice: false,
      } as DisplayableProduct
    }

    return {
      show,
      allProducts,
      currency,
      cost,
      participantTotal,
      showOptionalProductText,
      showMultiPriceWarning,
    }
  },
})
