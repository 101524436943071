


















































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'

import DivisionHeader from '@/views/Programs/Divisions/vues/DivisionHeader.vue'
import CoachManager from '@/views/Programs/Divisions/Coaches/CoachManager.vue'
import Loading from '@/elements/Loading.vue'
import SwapCoachAssignment from '@/components/SwapCoachAssignment.vue'

import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { HeaderValue } from '@/views/Programs/Divisions/ext/headerValue'
import { getUpwardLeagueId } from '@/views/Programs/Divisions/ext/divisionUtils'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { useManagerLogic } from '@/views/Programs/Divisions/Coaches/logic/ManagerLogic'

import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'

export default defineComponent({
  name: 'Coaches',
  components: {
    CoachManager,
    Loading,
    DivisionHeader,
    SwapCoachAssignment,
  },
  setup(props, ctx) {
    const headerValues = ref<HeaderValue | null>(null)
    const allLeagueDivisions = ref<LeagueDivisionInfo[] | null>(null)
    const manager = useManagerLogic()

    const programID = ref('')
    const divisionID = ref(-1)
    const teamsCount = ref(99)
    const showHeaders = ref(true)
    const showSwapCoachAssignment = ref(false)
    const isLoading = ref(false)

    function toggleHeader(val: any) {
      showHeaders.value = val
    }

    const upwardLeagueId = computed(() => getUpwardLeagueId(ctx.root))

    const division = computed(() => headerValues.value?.selectedDivision)

    const divisionId = computed(() => division.value?.divisionID || 0)

    const refreshingAll = computed(() => manager.refreshing.value)

    const templateDivision = computed(
      () => headerValues.value?.selectedDivision ?? getEmptyLeagueDivisionInfo()
    )

    async function onDivisionChanged(hdrVals: HeaderValue | null) {
      if (hdrVals) {
        headerValues.value = hdrVals
      }

      if (divisionID.value != hdrVals?.selectedDivision?.divisionID ?? -1) {
        manager.setDivisionAndRefresh(hdrVals?.selectedDivision ?? undefined)
      }
      programID.value = hdrVals?.selectedTypeProgramID ?? ''
      divisionID.value = hdrVals?.selectedDivision?.divisionID ?? -1

      await setTeamsCount(divisionID.value, programID.value)
      isLoading.value = false
    }

    onMounted(async () => {
      isLoading.value = true
      allLeagueDivisions.value = await divisionsClient.retrieveAll(upwardLeagueId.value)
      if (allLeagueDivisions.value?.length == 0) {
        isLoading.value = false
      }
    })
    const divisionCount = computed(() => {
      let count = allLeagueDivisions.value ? allLeagueDivisions.value.length : -1
      if (programID.value && allLeagueDivisions.value) {
        const divisions = allLeagueDivisions.value.filter((d) => d.typeProgramID == programID.value)
        count = divisions ? divisions.length : -1
      }
      return count
    })

    async function setTeamsCount(divID: number | null, typeProgramId: string) {
      if (divID === -1) {
        divID = null
      }

      if (previousDivisionID.value === divID && previousTypeProgramID.value === typeProgramId) {
        return
      }

      if (divID && typeProgramId) {
        previousDivisionID.value = divID
        previousTypeProgramID.value = typeProgramId

        const teams = await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: divID,
          typeProgramId: typeProgramId,
        })
        teamsCount.value = 0
        if (teams) {
          teamsCount.value = teams.length
        }
      }
    }

    const typeProgramId = computed(() => {
      if (
        headerValues.value &&
        headerValues.value.selectedDivision &&
        headerValues.value.selectedDivision.typeProgramID
      ) {
        return headerValues.value.selectedDivision.typeProgramID
      }

      return ''
    })

    const previousDivisionID = ref<number | null>(null)
    const previousTypeProgramID = ref<string | null>(null)

    async function updateTeamList() {
      if (!divisionId.value) {
        return
      }
      await manager.refresh()
    }

    return {
      showHeaders,
      onDivisionChanged,
      refreshingAll,
      showSwapCoachAssignment,
      isLoading,
      divisionCount,
      teamsCount,
      programID,
      divisionID,
      headerValues,
      toggleHeader,
      upwardLeagueId,
      divisionId,
      typeProgramId,
      updateTeamList,
      getEmptyLeagueDivisionInfo,
      templateDivision,
    }
  },
})
