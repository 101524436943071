






















import { defineComponent, ref, watch } from '@vue/composition-api'
import UpwardValidationObserver from '@/components/UpwardValidationObserver.vue'
import NumberInput from '@/elements/NumberInput.vue'

import { productFlowLogicUR } from '@/components/UpwardRunning/ProductFlow/productFlowLogicUR'
import store from '@/store'

export default defineComponent({
  name: 'ProgramInformationFormUR',
  components: {
    NumberInput,
    UpwardValidationObserver,
  },
  setup(props, ctx) {
    const logic = productFlowLogicUR()
    const estimate = ref(logic.challenge.value.participantEstimate)
    const isValid = ref(false)

    function sendDataToStore() {
      logic.markAsDirty()
      store.commit.upwardRunning.setChallengeParticipantEstimate({ est: estimate.value ?? 0 })
    }

    function onValidEvent(v: boolean) {
      isValid.value = v
    }

    watch(
      () => isValid.value,
      () => {
        ctx.emit('valid', isValid.value)
      }
    )

    return {
      onValidEvent,
      estimate,
      sendDataToStore,
    }
  },
})
