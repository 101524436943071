





























import { defineComponent, ref, watch, PropType } from '@vue/composition-api'

import CurrencyInput from '@/elements/CurrencyInput.vue'
import { isEqual } from 'lodash'

import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'
import store from '@/store'

export default defineComponent({
  name: 'RunningRegistrationFee',
  components: {
    CurrencyInput,
  },
  props: {
    value: { type: Object as PropType<PartnerChallenge>, required: true },
    hasEarlyRegistration: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const challenge = ref<PartnerChallenge>({ ...props.value })

    function sendDataToStore() {
      store.commit.upwardRunning.setChallengeEarlyRegistrationFee({
        fee: challenge.value.earlyRegistrationFee ?? 0,
      })
      store.commit.upwardRunning.setChallengeRegistrationFee({
        fee: challenge.value.registrationFee ?? 0,
      })
    }

    watch(
      () => props.value,
      () => {
        if (!isEqual(challenge.value, props.value)) {
          challenge.value = { ...props.value }
        }
      },
      { deep: true }
    )

    watch(
      () => challenge.value,
      () => {
        if (!isEqual(challenge.value, props.value)) {
          ctx.emit('input', challenge.value)
        }
      },
      { deep: true }
    )

    return {
      challenge,
      sendDataToStore,
    }
  },
})
