



















































































import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import DayOfWeekPicker from '@/elements/DayOfWeekPicker.vue'
import AdditionalParticipantInformationUR from '@/components/UpwardRunning/AdditionalParticipantInformationUR.vue'
import TimezoneSelect from '@/components/TimezoneSelect.vue'
import Modal from '@/components/Modal.vue'
import SaveContinueButtonUR from '@/elements/UpwardRunning/SaveContinueButtonUR.vue'
import TimePicker from '@/elements/TimePicker2.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { PartnerChallengeUDFDefinition } from '@/models/UpwardRunning/PartnerChallengeUDFDefinition'

@Component({
  components: {
    TextInput,
    MemoInput,
    YesNoInput,
    DayOfWeekPicker,
    TimePicker,
    Loading,
    AdditionalParticipantInformationUR,
    TimezoneSelect,
    Modal,
    SaveContinueButtonUR,
    HtmlEditor,
  },
})
export default class Information extends Mixins(UpwardRunningSetupMixin) {
  private internalPracticeNights: string[] = []
  private helpIsVisible = false

  private cutOffHelpIsVisible = false

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.challenge.upwardChallengeID) {
      this.nextRoute = `/programs/running/${this.challenge.upwardChallengeID}/setup/dates`
    } else {
      this.nextRoute = '/programs/running/setup/dates'
    }
  }

  private saveUdfs(udfs: PartnerChallengeUDFDefinition[]) {
    this.challenge.udfs = udfs
  }
}
