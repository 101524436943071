




















































import UpwardRunningSetupMixin from '@/views/UpwardRunning/UpwardRunningSetupMixin'
import Loading from '@/elements/Loading.vue'
import DateInput from '@/elements/DateInput.vue'
import { Component, Mixins } from 'vue-property-decorator'
import SaveContinueButtonUR from '@/elements/UpwardRunning/SaveContinueButtonUR.vue'

@Component({
  components: {
    DateInput,
    Loading,
    SaveContinueButtonUR,
  },
})
export default class KeyDates extends Mixins(UpwardRunningSetupMixin) {
  mounted() {
    if (this.challenge.upwardChallengeID) {
      this.nextRoute = `/programs/running/${this.challenge.upwardChallengeID}/setup/leadership`
    } else {
      this.nextRoute = '/programs/running/setup/leadership'
    }
  }
}
