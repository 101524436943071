import { DataTableHeader } from 'vuetify/types'
export interface DataTableSelection<T> {
  expand: (value: boolean) => void
  headers: DataTableHeader[]
  isExpanded: boolean
  isMobile: boolean
  isSelected: boolean
  item: T
  select: (value: boolean) => void
}

export interface DataTablePagination {
  page: number
  itemsPerPage: number
  pageStart: number
  pageStop: number
  pageCount: number
  itemsLength: number
}

export interface DataTableRemovedPagination {
  removedPage: number
  removedItemsPerPage: number
  removedPageStart: number
  removedPageStop: number
  removedPageCount: number
  removedItemsLength: number
}

export function getEmptyDataTablePagination() {
  return {
    page: 1,
    itemsPerPage: 15,
    pageStart: 1,
    pageStop: 1,
    pageCount: 1,
    itemsLength: 1,
  }
}

export function getEmptyDataTableRemovedPagination() {
  return {
    removedPage: 1,
    removedItemsPerPage: 5,
    removedPageStart: 1,
    removedPageStop: 1,
    removedPageCount: 1,
    removedItemsLength: 1,
  }
}
